export async function asyncGetImages(array) {
    const images = [];
    await asyncForEach(array, async (data) => {
        const response = await fetchAsBlob(data.url);
        images.push(response);
    });

    return images;
}

export async function asyncGetImage(url) {
    return await fetchAsBlob(url);
}

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function fetchAsBlob(url) {
    if (Boolean(url)) {
        return fetch(url).then(response => response.blob());
    }
    return ""
}

