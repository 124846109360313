import React      from "react";
import Typography from "@material-ui/core/Typography";

export function missing_permissions(errors) {
    return <>
        <Typography>Il vous manque des permissions.</Typography>
        <Typography>Veuillez contacter votre superviseur si vous croyez en avoir de besoin.</Typography>
    </>;
}

