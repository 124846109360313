import axios      from "app/services/axios/CustomAxios";
import { apiUrl } from "app/services/axios/configApi";

export function megaSearch(value) {
    return axios.get(apiUrl + "/common/megasearch", {
        params: {query: value},
    }).then(function(response) {
        return response;
    });
}
