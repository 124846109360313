import React                  from "react";
import { connect }            from "react-redux";
import { logoutUser }         from "app/auth/store/actions";
import { bindActionCreators } from "redux";
import { faPowerOff }         from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon }    from "@fortawesome/react-fontawesome";
import Button                 from "@material-ui/core/Button";

function Logout(props) {
    return <Button
        onClick={props.logoutUser}
        variant="contained"
        color={"primary"}
        fullWidth
        size={"large"}
    >
        <FontAwesomeIcon icon={faPowerOff} className={"mr-8"}/> {" Se Déconnecter"}
    </Button>;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logoutUser,
    }, dispatch);
}

export default (connect(null, mapDispatchToProps)(Logout));