import React from "react";

export const InternetServiceConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes  : [
        //internetPlan
        {
            path       : "/forfaits-internet/modifier/:id",
            component  : React.lazy(() => import("app/main/components/services/internetService/internetPlan/updateInternetPlan/UpdateInternetPlan")),
            permissions: ["manage_internet_services"],
        },
        {
            path       : "/forfaits-internet/creer",
            component  : React.lazy(() => import("app/main/components/services/internetService/internetPlan/createInternetPlan/CreateInternetPlan")),
            permissions: ["manage_internet_services"],
        },
        {
            path       : "/forfaits-internet",
            component  : React.lazy(() => import("app/main/components/services/internetService/internetPlan/showInternetPlans/InternetPlans")),
            permissions: ["manage_internet_services"],
        },

        //internetPlanCategory
        {
            path       : "/categories-forfait-internet/modifier/:id",
            component  : React.lazy(() => import("app/main/components/services/internetService/internetPlanCategory/updateInternetPlanCategory/UpdateInternetPlanCategory")),
            permissions: ["manage_internet_services"],
        },
        {
            path       : "/categories-forfait-internet/creer",
            component  : React.lazy(() => import("app/main/components/services/internetService/internetPlanCategory/createInternetPlanCategory/CreateInternetPlanCategory")),
            permissions: ["manage_internet_services"],
        },
        {
            path       : "/categories-forfait-internet",
            component  : React.lazy(() => import("app/main/components/services/internetService/internetPlanCategory/showInternetPlanCategories/InternetPlanCategories")),
            permissions: ["manage_internet_services"],
        },

        //internetPlanPackage
        {
            path       : "/groupes-forfait-internet/modifier/:id",
            component  : React.lazy(() => import("app/main/components/services/internetService/internetPlanPackage/updateInternetPlanPackage/UpdateInternetPlanPackage")),
            permissions: ["manage_internet_services"],
        },
        {
            path       : "/groupes-forfait-internet/creer",
            component  : React.lazy(() => import("app/main/components/services/internetService/internetPlanPackage/createInternetPlanPackage/CreateInternetPlanPackage")),
            permissions: ["manage_internet_services"],
        },
        {
            path       : "/groupes-forfait-internet",
            component  : React.lazy(() => import("app/main/components/services/internetService/internetPlanPackage/showInternetPlanPackages/InternetPlanPackages")),
            permissions: ["manage_internet_services"],
        },

        //internetSession
        {
            path       : "/sessions",
            component  : React.lazy(() => import("app/main/components/services/internetService/internetSessions/showInternetSessions/InternetSessions")),
            permissions: ["manage_internet_services"],
        },

        //internetSupply
        {
            path       : "/internets/equipements",
            component  : React.lazy(() => import("app/main/components/services/internetService/internetSupply/showInternetSupply/InternetSupplies")),
            permissions: ["manage_internet_services"],
        },

        //staticIp
        {
            path       : "/ip-statiques",
            component  : React.lazy(() => import("app/main/components/services/internetService/staticIp/showStaticIps/StaticIps")),
            permissions: ["manage_internet_services"],
        },
    ],
};