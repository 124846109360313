import React, {Component}   from "react";
import Button               from "@material-ui/core/Button";
import Dialog               from "@material-ui/core/Dialog";
import DialogActions        from "@material-ui/core/DialogActions";
import DialogTitle          from "@material-ui/core/DialogTitle";
import {Grid, withStyles}   from "@material-ui/core";
import panelConfig          from "app/fuse-configs/panelConfig";
import {bindActionCreators} from "redux";
import * as Actions         from "app/store/actions";
import Card                 from "@material-ui/core/Card";
import CardContent          from "@material-ui/core/CardContent";
import {FontAwesomeIcon}    from "@fortawesome/react-fontawesome";
import {faTimes}            from "@fortawesome/pro-solid-svg-icons/faTimes";
import {connect}            from "react-redux";
import {faHexagon}          from "@fortawesome/pro-regular-svg-icons";
import withWidth            from "@material-ui/core/withWidth";
import history       from "@history";
import PrimaryButton from "app/main/genericComponents/buttons/rectangleButtons/PrimaryButton";

const styles = theme => ({
    bottomButton    : {
        backgroundColor: theme.palette.secondary.main,
    },
    bottomButtonDark: {
        backgroundColor: theme.palette.secondary.dark,
    },
    card            : {
        border   : "2px solid " + theme.palette.secondary.main,
        "&:hover": {
            cursor         : "pointer",
            backgroundColor: "#3A3225",
        },
    },
    dialogHeader    : {
        backgroundImage: "linear-gradient(to right,#15354b," + theme.palette.primary.main + ")",
    },
    root            : {
        color: "white",
    },
});

class AlertDialog extends Component {

    constructor(props) {
        super(props);
        this.state = ({open: false, current: ""});
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClickOpen() {
        this.setState({open: true});
    }

    handleClose() {
        this.setState({open: false});
    }

    handleButtonClick(buttontext) {
        this.setState({open: false});
        this.setPanelAndRedirect(buttontext);
    }

    renderbuttonList() {
        return this.getPanels().map((button) =>

                                        <React.Fragment key={button.value}>
                                            <Card onMouseEnter={() => this.setState({current: button.traduction})}
                                                  onMouseLeave={() => this.setState({current: ""})}
                                                  className={this.props.classes.card}>
                                                <CardContent>
                                                    <Button
                                                        fullWidth
                                                        color={"secondary"}
                                                        onClick={() => this.handleButtonClick(button.value)}
                                                    >
                            <span className="fa-layers fa-fw"
                                  style={{fontSize: (this.props.width === "xs" || this.props.width === "sm") ? 40 : 60}}>
                                {button.text}
                                <FontAwesomeIcon icon={faHexagon} transform="grow-10"/>
                            </span>
                                                    </Button>
                                                </CardContent>
                                                <div
                                                    className={"mx-auto min-w-full min-h-full text-center text-20 pt-4 pb-4 "
                                                               + ((this.state.current === button.traduction) ? this.props.classes.bottomButtonDark : this.props.classes.bottomButton)}
                                                    onClick={() => this.handleButtonClick(button.value)}
                                                    children={button.traduction}/>
                                            </Card>


                                        </React.Fragment>,
        );
    }

    getPanels() {
        let pannelButtons = [];
        for (let panelName in panelConfig) {
            const panel = panelConfig[panelName];
            if (this.props.user && this.props.user.data && this.props.user.data.permissions && this.props.user.data.permissions.find(function (permission) {
                return permission.code === panel.permission;
            })) {

                pannelButtons.push({
                                       value     : panel.name,
                                       traduction: panel.traduction,
                                       text      : panel.logo,
                                   });

            }
        }
        return pannelButtons;
    }

    setPanelAndRedirect(pannel) {
        this.props.setPanel(pannel);
        history.push("/tableau-de-bord");
    }

    render() {
        return (
            <div>

                <Button color="secondary"
                        className={this.props.classes.panelButton + " " + this.props.openModalButtonStyle}
                        onClick={this.handleClickOpen}
                        disabled={this.props.currentPanel === "contractorPanel"}>
                    {this.props.openModalButtonRender}
                </Button>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Grid container className={this.props.classes.dialogHeader}>

                        <Grid item xs={11}>
                            <DialogTitle className={this.props.classes.root}>
                                Veuillez choisir votre panneau administratif
                            </DialogTitle>
                        </Grid>

                        <Grid item xs={1} style={{display: "flex", flexDirection: "row-reverse"}}>
                            <PrimaryButton
                                disableElevation={true}
                                onClick={(event) => this.handleClose(event)}
                                buttontext={<FontAwesomeIcon icon={faTimes} size="3x"/>}
                            />
                        </Grid>

                    </Grid>

                    <DialogActions className={"flex justify-around p-8"}>
                        {this.renderbuttonList()}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
                                  setPanel: Actions.setPanel,
                              }, dispatch);
}

function mapStateToProps({fuse, auth}) {
    return {
        currentPanel: fuse.navigation.currentPanel,
        user        : auth.user,
    };
}

export default withStyles(styles, {withTheme: true})(withWidth()(connect(mapStateToProps, mapDispatchToProps)(AlertDialog)));