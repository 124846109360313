import React, {Component}    from "react";
import DefaultSelectField    from "app/main/genericComponents/formField/SelectFields/genericSelectField/DefaultSelectField";
import {isApiCallSuccessful} from "app/services/axios/genericAction";

export default class ApiCallSelectField extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {items: [], loading: false};
    }

    componentDidMount() {
        this._isMounted = true;
        this.handleUpdate();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.needUpdate !== this.props.needUpdate && this.props.needUpdate) {
            this.handleUpdate();
            this.props.afterUpdate();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleUpdate() {
        this.setState({loading: true});
        this.props.getItems()
            .then((response) => {
                if (this._isMounted && isApiCallSuccessful(response)) {
                    const items = this.props.mapItems(response.data.data)
                    this.setState({items: items, loading: false})
                }
            });
    }

    render() {
        return <DefaultSelectField
            {...this.props}
            items={this.state.items}
            onChange={(event) => this.props.onChange(event)}
        />;
    }
}
