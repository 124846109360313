import axios      from "app/services/axios/CustomAxios";
import { apiUrl } from "app/services/axios/configApi";

export function readAllTrucks() {
    return axios(apiUrl + "/inventory/trucks");
}

export function readTruckInventory(truck_id) {

    return axios(apiUrl + "/inventory/storageables/truck/"+truck_id+"/supplies", {
        params: {
            "supply_group_type": "by_state",
        },
    });
}

export function createTruck(truckInformation) {

    return axios.post(apiUrl + "/inventory/trucks", {
                    "name"                : truckInformation.name,
                    "license_plate"       : truckInformation.license_plate,
                    "responsible_admin_id": truckInformation.responsible_admin_id,
                })
                .catch(error => {
                    return error.response;
                });
}

export function saveTruck(truckInformation) {

    return axios.put(apiUrl + "/inventory/trucks/" + truckInformation.id, {
                    "name"                : truckInformation.name,
                    "license_plate"       : truckInformation.license_plate,
                    "responsible_admin_id": truckInformation.responsible_admin_id,
                })
                .catch(error => {
                    return error.response;
                });
}

export function readTruck(id) {
    return axios(apiUrl + "/inventory/trucks/" + id);
}

export function deleteTruck(truckId) {
    return axios.delete(apiUrl + "/inventory/trucks/" + truckId)
                .catch(error => {
                    return error.response;
                });
}