import React      from "react";
import Typography from "@material-ui/core/Typography";

export function internet_plan_not_in_transmitter(errors) {
    return <>
        <Typography>Le forfait internet n'est pas disponible dans le transmetteur</Typography>
        <Typography>Veuillez en choisir un autre ou changer de transmetteur.</Typography>
    </>;
}

