import React, { Component }         from "react";
import Button                       from "@material-ui/core/Button";
import * as NavigationActions       from "app/store/actions";
import { connect }                  from "react-redux";
import { bindActionCreators }       from "redux";
import { FontAwesomeIcon }          from "@fortawesome/react-fontawesome";
import { faWarehouse }              from "@fortawesome/pro-solid-svg-icons/faWarehouse";
import { faChevronDown }            from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import ControlledDialog       from "app/main/genericComponents/dialog/ControlledDialog";
import StorageableSelectField from "app/main/genericComponents/formField/SelectFields/inventorySelectField/StorageableSelectField";
import { readWarehouse }      from "app/services/axios/Inventory/warehouse";
import { withStyles }               from "@material-ui/styles";
import history                      from "@history";

const styles = ({
    dialogPosition       : {
        textAlign: "center",
    },
    selectWarehouseButton: {
        maxWidth    : 265,
        marginBottom: 20,
    },
});

class SelectWarehouseDialog extends Component {

    constructor(props) {
        super(props);
        this.state        = {
            open            : false,
            selected        : "",
            currentWarehouse: {},
        };

    }

    componentDidMount() {
        if ((this.props.user.data) && this.props.user.data.permissions.find(function(permission) {
            return permission.code === "display_warehouse";
        })) {
            this.setState({open: !this.hasCurrentWarehouse()});
            if (this.hasCurrentWarehouse()) {
                readWarehouse(this.props.currentWarehouse.id)
                    .then((response) => this.setState({currentWarehouse: response.data.data}));
            }
        }
    }

    hasCurrentWarehouse() {
        return Boolean(this.props.currentWarehouse.id) && Boolean(localStorage.getItem("currentWarehouse"));
    }

    handleClose() {
        if (this.props.currentWarehouse) {
            this.setState({open: false});
        }
    }

    handleOpen() {
        this.setState({open: true});
    }

    handleSubmit() {
        if (this.state.selected.id) {
            readWarehouse(this.state.selected.id)
                .then((response) => {
                    this.setState({open: false, currentWarehouse: response.data.data});
                    this.props.setWarehouse({id: this.state.selected.id, name: response.data.data.name});
                });

            history.push("/tableau-de-bord");
        }
    }

    renderButton(menuIsFolded) {
        if (menuIsFolded && this.state.currentWarehouse.id !== undefined) {

            const currentWarehouse = this.state.currentWarehouse;

            if (currentWarehouse) {
                return <Button
                    color={"secondary"}
                    variant={"contained"}
                    className={this.props.classes.selectWarehouseButton}
                >

                    <FontAwesomeIcon className={"mr-4"} icon={faWarehouse}/>
                    {currentWarehouse.name}
                    <FontAwesomeIcon className={"ml-4"} icon={faChevronDown}/>

                </Button>;
            }
        }

        return <Button color={"secondary"} variant={"contained"}>
            <FontAwesomeIcon icon={faWarehouse}/>
        </Button>;
    }

    render() {
        if (this.props.currentPanel === "warehousePanel") {
            return (

                <div className={this.props.classes.dialogPosition}>

                    <div onClick={()=>this.handleOpen()}>
                        {this.renderButton(this.props.isMenuFolded)}
                    </div>

                    <ControlledDialog
                        open={this.state.open}
                        title={"Veuiller choisir votre entrepôt"}
                        content={
                            <StorageableSelectField
                                value={this.state.selected}
                                withDefaultValue={false}
                                storageableType={"warehouse"}
                                onChange={(event) => this.setState({selected: event.value})}
                            />
                        }
                        handleClose={()=>this.handleClose()}
                        handleSubmit={()=>this.handleSubmit()}
                    />

                </div>
            );
        }
        return null;
    }
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators({
        setWarehouse: NavigationActions.setWarehouse,
    }, dispatch);
}

function mapStateToProps({fuse, auth}) {
    return {
        currentPanel    : fuse.navigation.currentPanel,
        currentWarehouse: fuse.navigation.currentWarehouse,
        isMenuFolded    : fuse.navbar.foldedOpen,
        user            : auth.user,
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SelectWarehouseDialog));