import { AT_USER }      from "./actions-types";
import axios            from "../../../services/axios/CustomAxios";
import {apiUrl}           from "app/services/axios/configApi";
import _                from '@lodash';

export function updateUserData(next = () => {}) {
    return function(dispatch) {
        axios.get(apiUrl + "/auth/currentInfo").then(response => {
            if (! response) {
                return response.data.error;
            }
            if(Boolean(response.data.data.permissions)){
                dispatch(setUserData(response.data.data));
            }else{
                dispatch(setUserData({...response.data.data,permissions:[{code:"contractor_panel"}]}));
            }

            next();
        });
    };
}

export function setUserData(user) {
    return function(dispatch) {
        dispatch({
            type   : AT_USER.USER_UPDATE_CURRENT,
            payload: user,
        });

    };
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings)
{
    return (dispatch, getState) => {
        const oldUser = getState().auth.user;
        const user = _.merge({}, oldUser, {data: {settings}});

        updateUserData(user);

        return dispatch(setUserData(user));
    }
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts)
{
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const newUser = {
            ...user,
            data: {
                ...user.data,
                shortcuts
            }
        };

        updateUserData(newUser);

        return dispatch(setUserData(newUser));
    }
}
