import React, { Component } from "react";
import { Carousel }         from "react-responsive-carousel";
import noImageIcon          from "app/images/aucune-image-disponible.jpg";
import { apiUrl }           from "app/services/axios/configApi";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withStyles }       from "@material-ui/styles";

const styles = ({
    img: {
        objectFit: "contain",
    },
});

class NormalCarousel extends Component {

    renderImage(image) {
        if (image.url.includes("blob")) {
            return <div key={image}>
                <img className={this.props.classes.img}
                     src={image.url}
                     alt={""}
                     width={this.props.size}
                     height={this.props.size}
                />
            </div>;
        }
        return <div key={image}>
            <img className={this.props.classes.img}
                 src={apiUrl + image.url}
                 width={this.props.size}
                 height={this.props.size}
                 alt={""}
            />
        </div>;
    }

    render() {

        return <Carousel
            showThumbs={false}
            onChange={this.props.onChange}
            selectedItem={this.props.selectedItem}
            infiniteLoop={true}>
            {this.props.image_src.length === 0 ?
                <div className={"bg-white"}>
                    <img src={noImageIcon} alt={"noImage"}
                         className={this.props.classes.img}
                         width={this.props.size}
                         height={this.props.size}/>
                </div>
                : this.props.image_src.map((image) => { return this.renderImage(image); })}
        </Carousel>;
    }
}

export default withStyles(styles)(NormalCarousel);