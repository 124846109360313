import * as Actions from '../../actions/fuse/index';

const initialState = {
    foldedOpen: true,
    mobileOpen: false,
    searchSuggestion: []
};

const navbar = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.TOGGLE_FOLDED_NAVBAR:
        {
            return {
                ...state,
                foldedOpen: !state.foldedOpen
            }
        }
        case Actions.OPEN_FOLDED_NAVBAR:
        {
            return {
                ...state,
                foldedOpen: true
            }
        }
        case Actions.CLOSE_FOLDED_NAVBAR:
        {
            return {
                ...state,
                foldedOpen: false
            }
        }
        case Actions.TOGGLE_MOBILE_NAVBAR:
        {
            return {
                ...state,
                mobileOpen: !state.mobileOpen
            }
        }
        case Actions.OPEN_MOBILE_NAVBAR:
        {
            return {
                ...state,
                mobileOpen: true
            }
        }
        case Actions.CLOSE_MOBILE_NAVBAR:
        {
            return {
                ...state,
                mobileOpen: false
            }
        }
        case Actions.READ_SEARCH_SUGGESTION : {
            let newState;
            newState = {
                ...state,
                searchSuggestion:  (action.payload)?action.payload:[],
            };
            return newState;
        }
        default:
        {
            return state;
        }
    }
};

export default navbar;