import axios    from "app/services/axios/CustomAxios";
import {apiUrl} from "app/services/axios/configApi";

export function readAllDeliveries() {
    return axios(apiUrl + "/inventory/deliveries");
}

export function readAllDeliveriesTowardStorages(storageable_type, storageable_id) {
    return axios(apiUrl + "/inventory/storageables/" + storageable_type + "/" + storageable_id + "/deliveriesTowards");
}

export function readAllDeliveriesFromStorages(storageable_type, storageable_id) {
    return axios(apiUrl + "/inventory/storageables/" + storageable_type + "/" + storageable_id + "/deliveriesFrom");
}

export function readAllDeliveriesWithTrashed() {
    return axios(apiUrl + "/inventory/deliveries", {params: {with_trashed: 1}});
}

export function readDeliveriesOfStorage(storageableType, storagebaleId,withTrashed=0) {
    return axios(apiUrl + "/inventory/deliveries/byStorage/" + storageableType + "/" + storagebaleId, {params: {with_trashed: withTrashed}});
}

export function setCompleteDelivery(id) {
    return axios.post(apiUrl + "/inventory/deliveries/" + id + "/ready");
}

export function createDelivery(deliveryInformation) {
    return axios.post(`${apiUrl + "/inventory/deliveries"}`, {
        "origin_storageable_type"     : deliveryInformation.origin_storageable_type,
        "origin_storageable_id"       : deliveryInformation.origin_storageable_id,
        "destination_storageable_type": deliveryInformation.destination_storageable_type,
        "destination_storageable_id"  : deliveryInformation.destination_storageable_id,
        "responsible_admin_id"        : deliveryInformation.responsible_admin_id,
        "zoho_code"                   : deliveryInformation.zoho_code,
    })
        .catch(error => {
            return error.response;
        });
}

export function updateDelivery(deliveryInformation) {
    return axios.put(`${apiUrl + "/inventory/deliveries/" + deliveryInformation.id}`, {
        "destination_storageable_type": deliveryInformation.destination_storageable_type,
        "destination_storageable_id"  : deliveryInformation.destination_storageable_id,
        "responsible_admin_id"        : deliveryInformation.responsible_admin_id,
        "zoho_code"                   : deliveryInformation.zoho_code,
    }).catch(error => {
        return error.response;
    });
}

export function readDelivery(id) {
    return axios(apiUrl + "/inventory/deliveries/" + id);
}

export function deleteDelivery(deliveryId) {
    return axios.post(apiUrl + "/inventory/deliveries/" + deliveryId + "/cancel")
        .catch(error => {
            return error.response;
        });
}

export function createBox(deliveryId, boxCode) {
    return axios.post(apiUrl + "/inventory/supply_emplacements", {
        "storageable_id"         : deliveryId,
        "storageable_type"       : "delivery",
        "supply_emplacement_type": "box",
        "supply_emplacement_code": "box" + boxCode,
    });
}

export function createBac(deliveryId, bacCode) {
    return axios.post(apiUrl + "/inventory/supply_emplacements", {
        "supply_emplacement_type": "bac",
        "supply_emplacement_code": bacCode,
        "storageable_type"       : "delivery",
        "storageable_id"         : deliveryId,
    }).catch(error => {
        return error.response;
    });
}

export function recuperateDelivery(moveInformation) {
    return axios.post(apiUrl + "/inventory/deliveries/" + moveInformation.id + "/complete", {
        "destination_id": moveInformation.destination_id,
        "bundles"       : moveInformation.bundles,
        "stocks"        : moveInformation.stocks,
    }).catch(error => {
        return error.response;
    });
}