import React from "react";

export const CustomersConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes  : [
        {
            path       : "/clients/:customerNo",
            component  : React.lazy(() => import("app/main/components/customer/ShowCustomer/ShowCustomer")),
            permissions: ["display_atombo2_customer"],
        },
        {
            path       : "/internetSessions/:id",
            component  : React.lazy(() => import("app/main/components/customer/ShowCustomer/customerInternetService/customerInternetSession/CustomerInternetSessions")),
            permissions: ["display_atombo2_customer"],
        },
        {
            path       : "/clients",
            component  : React.lazy(() => import("app/main/components/customer/ShowCustomers/Customers")),
            permissions: ["display_atombo2_customer"],
        },
        {
            path       : "/television-service/modifier/:id",
            component  : React.lazy(() => import("app/main/components/customer/ShowCustomer/customerTelevisionService/initialiseTelevisionService/UpdateTelevisionService")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/television-service/estimer",
            component  : React.lazy(() => import("app/main/components/customer/ShowCustomer/customerTelevisionService/estimateTelevisionService/EstimateTelevisionServiceCost")),
            permissions: ["manage_television_service"],
        },
    ],
};
