import React      from "react";
import Grid       from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export function locked_channel(errors) {

    return <div className={"max-w-sm"}>
        <Typography>Le client a les chaines suivantes barrées.</Typography>
        <Typography>Vérifiez que le changement respecte notre politique de vente avec votre superviseur.</Typography>
        <Grid container>
            {errors.locks.slice(0, 10)
                   .map(lock => {
                       let date = new Date(lock.end_date);
                       date     = date.toLocaleDateString("default", {
                           year : "numeric",
                           month: "long",
                           day  : "numeric",
                       });
                       return <>
                           <Grid item xs={6}>
                               {lock.channel.name_fr}
                           </Grid>
                           <Grid item xs={6}>
                               {"Date fin : " + date}
                           </Grid>
                       </>;
                   })}
        </Grid>
    </div>;
}

