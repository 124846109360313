import React        from 'react';
import Popover      from '@material-ui/core/Popover';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper  : {
        padding: theme.spacing(1),
    },
}));

export default function CustomPopover(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>

            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                style={{display: "inline-block"}}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {props.children}
            </div>
            {props.content && (
                <>
                    <Popover
                        id="mouse-over-popover"
                        className={classes.popover}
                        classes={{
                            paper: classes.paper,
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical  : 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical  : 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {props.content}
                    </Popover>
                </>
            )}

        </div>
    );
}