import React, {Component} from "react";
import TextFieldComponent from "app/main/genericComponents/formField/TextFieldComponent";
import {Button}           from "@material-ui/core";
import {FontAwesomeIcon}  from "@fortawesome/react-fontawesome";
import DragAndDrop        from "app/main/genericComponents/dragAndDrop";
import NormalCarousel     from "app/main/genericComponents/NormalCarousel";
import {faPlusCircle}     from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import {faMinusCircle}    from "@fortawesome/pro-solid-svg-icons/faMinusCircle";
import {faComment}        from "@fortawesome/pro-solid-svg-icons/faComment";
import Grid               from "@material-ui/core/Grid";
import {createFeedback}   from "app/services/axios/Common/feedback";
import FormDialog         from "app/main/genericComponents/dialog/FormDialog";

class FeedbackDialog extends Component {

    constructor(props) {
        super(props);
        this.initialState = {
            imageIndex: "",
            text      : "",
            files     : [],
            errors    : [],
        };
        this.state = this.initialState;
    }

    handleSubmit() {
        return createFeedback({message: this.state.text, files: this.state.files});
    }

    handleAddFilesToFeedback(event) {
        event.preventDefault();
        Array.from(event.target.files).forEach(file => {
            this.AddFiles(file);
        });
    }

    AddFiles(file) {
        const localFileUrl = window.URL.createObjectURL(file);
        this.setState({files: this.state.files.concat({url: localFileUrl})});
    }

    removeFiles(index) {
        const fileIndex = (index) ? index : 0;
        URL.revokeObjectURL(this.state.files[fileIndex]);
        this.setState({files: this.state.files.filter((item, i) => i !== fileIndex)});
    }

    render() {
        return (
            <FormDialog
                context={this}
                initialState={this.initialState}
                title={"Envoyer votre opinion"}
                renderOpenButton={<Button variant="contained" color={"primary"}><FontAwesomeIcon icon={faComment}/> Envoyer vos commentaires</Button>}
                content={<DragAndDrop handleDrop={(files) => Array.from(files).forEach((file) => this.AddFiles(file))}>
                    <Grid container>
                        <Grid item xs={8} className={"pr-12"}>
                            <TextFieldComponent
                                label={"Vos commentaires"}
                                name="message"
                                errors={this.state.errors.message}
                                value={this.state.text}
                                onChange={(event) => this.setState({text: event.target.value})}
                                required fullWidth autoFocus multiline
                                rowsMax="8"
                                rows={8}
                            />
                        </Grid>
                        <Grid item xs={4} className={"mt-4"}>
                            <NormalCarousel
                                size={"221px"}
                                image_src={this.state.files}
                                onChange={(id) => this.setState({"imageIndex": id})}/>
                            <input
                                className={"hidden"}
                                id="feedBackFile"
                                accept=".jpg, .jpeg, .png, .bmp"
                                multiple
                                type="file"
                                onChange={(event) => this.handleAddFilesToFeedback(event)}
                            />
                            <label htmlFor="feedBackFile">
                                <Button variant="contained" component="span" size={"small"}>
                                    Ajouter <FontAwesomeIcon icon={faPlusCircle} className={"ml-4"}/>
                                </Button>
                            </label>
                            <Button variant="contained" component="span" size={"small"}
                                    onClick={() => this.removeFiles(this.state.imageIndex)}>
                                Supprimer <FontAwesomeIcon icon={faMinusCircle} className={"ml-4"}/>
                            </Button>
                        </Grid>
                    </Grid>
                </DragAndDrop>}
                handleSubmit={() => this.handleSubmit()}
            />
        );
    }
}

export default (FeedbackDialog);