import React from "react";

export const NetworkConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes  : [
        //Adtran
        {
            path       : "/adtran/ONT/:id",
            component  : React.lazy(() => import("app/main/components/network/adtran/UpdateAdtranONT/UpdateAdtranONTPage")),
            permissions: ["manage_ont"],
        },
        {
            path       : "/adtran/ONT",
            component  : React.lazy(() => import("app/main/components/network/adtran/ShowAdtranONTs")),
            permissions: ["manage_ont"],
        },
        {
            path       : "/adtran/provisionner",
            component  : React.lazy(() => import("app/main/components/network/adtran/provisionAdtranONT/ProvisionAdtranONTPage")),
            permissions: ["manage_ont"],
        },
        {
            path       : "/adtran/remplacer",
            component  : React.lazy(() => import("app/main/components/network/adtran/replaceAdtranONT/ReplaceAdtranONTPage")),
            permissions: ["manage_ont"],
        },

        //ontPonStats
        {
            path       : "/opticalLineTerminations/:id/ontPonStats",
            component  : React.lazy(() => import("app/main/components/network/node/opticalLineTermination/ontPonStats/OpticalLineTerminationOntPonStatsPage")),
            permissions: ["display_pon_infos"],
        },
        {
            path       : "/opticalLineTerminations/:id/snmpTraps",
            component  : React.lazy(() => import("app/main/components/network/node/opticalLineTermination/SnmpTraps/OltSnmpTrapIndexPage")),
            permissions: ["display_pon_infos"],
        },
        {
            path       : "/opticalLineTerminations",
            component  : React.lazy(() => import("app/main/components/network/opticalLineTermination/ShowOpticalLineTerminations/OpticalLineTerminationsList")),
            permissions: ["display_internet_services"],
        },

        //NetworkRings
        {
            path       : "/networkRings/:id",
            component  : React.lazy(() => import("app/main/components/network/networkRing/showNetworkRing/ShowNetworkRing")),
            permissions: ["display_internet_services"],
        },
        {
            path       : "/networkRings",
            component  : React.lazy(() => import("app/main/components/network/networkRing/NetworkRings")),
            permissions: ["display_internet_services"],
        },

        //accessPoint
        {
            path       : "/point-access/:id",
            component  : React.lazy(() => import("app/main/components/network/accessPoint/showAccessPoint/ShowAccessPoint")),
            permissions: ["manage_internet_services"],
        },

        //cell
        {
            path       : "/cellules/nouvelle-cell",
            component  : React.lazy(() => import("app/main/components/network/cell/CreateCell/CreateCell")),
            permissions: ["manage_cell"],
        },
        {
            path       : "/cellules/modifier/:id",
            component  : React.lazy(() => import("app/main/components/network/cell/UpdateCell/UpdateCell")),
            permissions: ["manage_cell"],
        },
        {
            path       : "/cellules/:id",
            component  : React.lazy(() => import("app/main/components/network/cell/showCell/ShowCell")),
            permissions: ["display_cell"],
        },
        {
            path       : "/cellules",
            component  : React.lazy(() => import("app/main/components/network/cell/ShowCells/CellsList")),
            permissions: ["display_cell"],
        },

        //gateway
        {
            path       : "/gateways/modifier/:id",
            component  : React.lazy(() => import("app/main/components/network/gateway/updateGateway/UpdateGateway")),
            permissions: ["manage_internet_services"],
        },
        {
            path       : "/gateways/creer",
            component  : React.lazy(() => import("app/main/components/network/gateway/createGateway/CreateGateway")),
            permissions: ["manage_internet_services"],
        },
        {
            path       : "/gateways/:id",
            component  : React.lazy(() => import("app/main/components/network/gateway/showGateway/ShowGateway")),
            permissions: ["display_internet_services"],
        },
        {
            path       : "/gateways",
            component  : React.lazy(() => import("app/main/components/network/gateway/showGateways/Gateways")),
            permissions: ["display_internet_services"],
        },

        //node
        {
            path       : "/nodes/nouvelle-node",
            component  : React.lazy(() => import("app/main/components/network/node/CreateNode/CreateNode")),
            permissions: ["manage_node"],
        },
        {
            path       : "/nodes/modifier/:id",
            component  : React.lazy(() => import("app/main/components/network/node/UpdateNode/UpdateNode")),
            permissions: ["manage_node"],
        },
        {
            path       : "/nodes/:id",
            component  : React.lazy(() => import("app/main/components/network/node/ShowNode/ShowNode")),
            permissions: ["display_node"],
        },
        {
            path       : "/nodes",
            component  : React.lazy(() => import("app/main/components/network/node/ShowNodes/Nodes")),
            permissions: ["display_node"],
        },

        {
            path       : "/SnmpAlarmsName",
            component  : React.lazy(() => import("app/main/components/network/SNMP/ShowSNMPAlarmsNames/SnmpAlarmsName")),
            permissions: ["display_node"],
        },
        {
            path       : "/SnmpAlarmsName/create",
            component  : React.lazy(() => import("app/main/components/network/SNMP/CreateSNMPAlarmsNames/CreateSnmpAlarmName")),
            permissions: ["display_node"],
        },
    ],
};