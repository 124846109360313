import React            from 'react';
import {FuseNavigation} from '@fuse';
import {withRouter}     from 'react-router-dom';
import classNames       from 'classnames';
import { connect }      from "react-redux";

const Navigation = ({navigation, layout, dense, className}) => {
    return (
        <FuseNavigation className={classNames("navigation", className)} navigation={navigation.navigation} layout={layout} dense={dense}/>
    );
};

function mapStateToProps({fuse})
{
    return {
        navigation: fuse.navigation
    }
}

Navigation.defaultProps = {
    layout: "vertical"
};

export default withRouter(connect(mapStateToProps)(Navigation));
