import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PrimaryButton     from "app/main/genericComponents/buttons/rectangleButtons/PrimaryButton";
import {Grid}            from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    dialogHeader: {
        backgroundImage: "linear-gradient(to right,#15354b," + theme.palette.primary.main + ")",
    },
    root: {
        color: "white",
    },
});

class ControlledDialog extends Component {
    render() {
        return (
            <Dialog
                disableBackdropClick={this.props.disableBackdropClick}
                id={"controlledDialog"}
                fullWidth
                maxWidth={this.props.maxWidth}
                open={this.props.open}
                onClose={(event) => this.props.handleClose(event)}
                aria-labelledby="form-dialog-title"
            >
                <Grid container className={this.props.classes.dialogHeader}>

                    <Grid item xs={11}>
                        <DialogTitle id="form-dialog-title" className={this.props.classes.root}>
                            {this.props.title}
                        </DialogTitle>
                    </Grid>

                    {this.props.withButton && (
                        <Grid item xs={1} style={{display: "flex", flexDirection: "row-reverse"}}>
                            <PrimaryButton
                                disableElevation={true}
                                onClick={(event) => this.props.handleClose(event)}
                                buttontext={<FontAwesomeIcon icon={faTimes} size="2x"/>}
                            />
                        </Grid>
                    )}


                </Grid>

                <DialogContent className={"pt-10 pr-10 pl-10"}>
                    <DialogContentText id="alert-dialog-slide-description">
                        {this.props.text}
                    </DialogContentText>
                    {this.props.content}
                </DialogContent>

                <DialogActions className={"p-10"}>
                    <PrimaryButton
                        onClick={(event) => this.props.handleClose(event)}
                        buttontext={"Annuler"}
                        variant={"outlined"}
                        color={"primary"}
                    />

                    <PrimaryButton onClick={() => this.props.handleSubmit()}
                                   disabled={this.props.disabledSubmitButton}
                                   buttontext={this.props.submitButtonText}/>
                </DialogActions>
            </Dialog>
        );
    }
}

ControlledDialog.defaultProps = {
    disableBackdropClick: false,
    withButton: true,
    submitButtonText: "Confirmer",
    disabledSubmitButton: false,
    maxWidth: "md",
};

export default withStyles(styles, {withTheme: true})(ControlledDialog);