import { apiUrl } from "app/services/axios/configApi";
import axios      from "app/services/axios/CustomAxios";

export function readAllWarehouse() {
    return axios(apiUrl + "/inventory/warehouses")
}

export function readWarehouseInventory(warehouse_id) {

    return axios(apiUrl + "/inventory/storageables/warehouse/" + warehouse_id + "/supplies", {
        params: {
            "supply_group_type": "by_state",
        },
    });
}

export function createWarehouse(warehouseInformation) {

    return axios.post(apiUrl + "/inventory/warehouses", {
                    "name"                : warehouseInformation.name,
                    "civic_no"            : warehouseInformation.civic_no,
                    "odonym"              : warehouseInformation.odonym,
                    "city"                : warehouseInformation.city,
                    "postal_code"         : warehouseInformation.postal_code,
                    "responsible_admin_id": warehouseInformation.responsible_admin_id,
                    "location"            : warehouseInformation.location,
                })
                .catch(error => {
                    return error.response;
                });
}

export function saveWarehouse(warehouseInformation) {
    return axios.put(apiUrl + "/inventory/warehouses/" + warehouseInformation.id, {
                    "name"                : warehouseInformation.name,
                    "civic_no"            : warehouseInformation.civic_no,
                    "odonym"              : warehouseInformation.odonym,
                    "city"                : warehouseInformation.city,
                    "postal_code"         : warehouseInformation.postal_code,
                    "responsible_admin_id": warehouseInformation.responsible_admin_id,
                    "location"            : warehouseInformation.location,
                })
                .catch(error => {
                    return error.response;
                });
}

export function readWarehouse(id) {
    return axios(apiUrl + "/inventory/warehouses/" + id);
}

export function deleteWarehouse(id) {
    return axios.delete(apiUrl + "/inventory/warehouses/" + id)
                .catch(error => {
                    return error.response;
                });
}