import React from "react";

export const RegionsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes  : [
        //addresse
        {
            path       : "/addresse-installation/:region",
            component  : React.lazy(() => import("app/main/components/region/showAddressInstallations/AddressInstallationsList")),
            permissions: ["super_admin"],
        },

        //city
        {
            path       : "/villes/:region",
            component  : React.lazy(() => import("app/main/components/region/showCities/CitiesList")),
            permissions: ["super_admin"],
        },

        //region
        {
            path       : "/regions",
            component  : React.lazy(() => import("app/main/components/region/showRegions/RegionsList")),
            permissions: ["super_admin"],
        },
    ],
};