import axios      from "app/services/axios/CustomAxios";
import { apiUrl } from "app/services/axios/configApi";

export function getNotifications() {
    return axios.get(apiUrl + "/common/notifications");
}

export function readNotifications(notifications) {
    return axios.post(apiUrl + "/common/notifications/mark-as-read", {
        "notifications": notifications,
    }).catch(error => {
        return error.response;
    });
}