import React from "react";

export const TelephoneServiceConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes  : [
        //callRates
        {
            path       : "/longue-distance",
            component  : React.lazy(() => import("app/main/components/services/phoneService/callRates/showCallRates/CallRates")),
            permissions: ["manage_telephone_services"],
        },

        //E911Document
        {
            path       : "/Documents-E911/numéros-à-ignorer",
            component  : React.lazy(() => import("app/main/components/services/phoneService/documentE911/showDocumentsE911/E911ToIgnoreNumbers/ToIgnoreE911Numbers")),
            permissions: ["manage_e911Documents"],
        },
        {
            path       : "/Documents-E911",
            component  : React.lazy(() => import("app/main/components/services/phoneService/documentE911/showDocumentsE911/DocumentsE911")),
            permissions: ["manage_e911Documents"],
        },

        //PBX
        {
            path       : "/pbxs/creer",
            component  : React.lazy(() => import("app/main/components/services/phoneService/PBX/createPBX/CreatePbx")),
            permissions: ["manage_pbx"],
        },
        {
            path       : "/pbxs/:id",
            component  : React.lazy(() => import("app/main/components/services/phoneService/PBX/updatePbx/UpdatePbx")),
            permissions: ["manage_pbx"],
        },
        {
            path       : "/pbxs",
            component  : React.lazy(() => import("app/main/components/services/phoneService/PBX/showPBX/Pbxs")),
            permissions: ["display_pbx"],
        },

        //phoneLineDeleted
        {
            path       : "/numero-telephone/supprimer",
            component  : React.lazy(() => import("app/main/components/services/phoneService/PhoneLineDeleted/showPhoneLineDeleted/PhoneLinesDeleted")),
            permissions: ["manage_telephone_services"],
        },
        {
            path       : "/numero-telephone/supprimer-portage",
            component  : React.lazy(() => import("app/main/components/services/phoneService/PhoneLineDeleted/showPhoneLinePortOutDeleted/PhoneLinesPortOutDeleted")),
            permissions: ["manage_telephone_services"],
        },

        //phoneLineTest
        {
            path       : "/numero-telephone-tests",
            component  : React.lazy(() => import("app/main/components/services/phoneService/PhoneNumberForTest/showPhoneNumberForTests/PhoneNumberForTests")),
            permissions: ["manage_telephone_services"],
        },

        //phonePlan
        {
            path       : "/forfaits-telephone/modifier/:id",
            component  : React.lazy(() => import("app/main/components/services/phoneService/phonePlan/updatePhonePlan/UpdatePhonePlan")),
            permissions: ["manage_telephone_service"],
        },
        {
            path       : "/forfaits-telephone/creer",
            component  : React.lazy(() => import("app/main/components/services/phoneService/phonePlan/createPhonePlan/CreatePhonePlan")),
            permissions: ["manage_telephone_service"],
        },
        {
            path       : "/forfaits-telephone",
            component  : React.lazy(() => import("app/main/components/services/phoneService/phonePlan/showPhonePlans/PhonePlans")),
            permissions: ["display_telephone_service"],
        },

        //rateCenter
        {
            path       : "/rate-centers",
            component  : React.lazy(() => import("app/main/components/services/phoneService/rateCenter/showRateCenters/RateCenters")),
            permissions: ["manage_telephone_services"],
        },
    ],
};