import React, { Component }    from "react";
import { FontAwesomeIcon }     from "@fortawesome/react-fontawesome";
import { faBoxesAlt }     from "@fortawesome/pro-solid-svg-icons";
import GenericModelButton from "app/main/genericComponents/buttons/modelButtons/GenericModelButton";

class SupplyButton extends Component {

    render() {
        return (
            <GenericModelButton
                url={"/materiaux/" + this.props.supply.id}
                buttontext={this.props.supply.display_name}
                startIcon={<FontAwesomeIcon icon={faBoxesAlt} className={"opacity-75 ml-4"}/>}
            />
        );
    }
}

export default (SupplyButton);
