import React             from "react";
import Grid              from "@material-ui/core/Grid";
import Typography        from "@material-ui/core/Typography";
import EmplacementButton from "app/main/genericComponents/buttons/modelButtons/models/EmplacementButton";
import SupplyButton      from "app/main/genericComponents/buttons/modelButtons/models/SupplyButton";

export function supplies_in_emplacement(errors) {
    return <div className={"max-w-sm"}>
        <Typography className={"text-lg"}>Des matériaux sont dans l'emplacement
            <EmplacementButton className={"m-8"} emplacement={errors.emplacement}/>.
        </Typography>
        <Typography>Veuillez les retirer pour continuer.</Typography>
        <Grid container>
            {errors.supplies.slice(0, 10).map(supplyBundle => {
                return <>
                    <Grid item xs={10} className={"p-8 text-center flex"}>
                        <SupplyButton supply={supplyBundle.supply}/>
                    </Grid>
                    <Grid item xs={2} className={"pb-8 text-center items-center flex"}>
                        <Typography>{"Quantité : "+ supplyBundle.quantity}</Typography>
                    </Grid>
                </>;
            })}
        </Grid>
    </div>;
}

