import axios   from "app/services/axios/CustomAxios";
import {apiUrl}  from "app/services/axios/configApi";
import history from "@history";

export const OPEN_FOLDED_NAVBAR     = "[NAVBAR] OPEN FOLDED";
export const CLOSE_FOLDED_NAVBAR    = "[NAVBAR] CLOSE FOLDED";
export const TOGGLE_FOLDED_NAVBAR   = "[NAVBAR] TOGGLE FOLDED";
export const TOGGLE_MOBILE_NAVBAR   = "[NAVBAR] TOGGLE MOBILE";
export const OPEN_MOBILE_NAVBAR     = "[NAVBAR] OPEN MOBILE";
export const CLOSE_MOBILE_NAVBAR    = "[NAVBAR] CLOSE MOBILE";
export const READ_SEARCH_SUGGESTION = "[NAVBAR] READ SEARCH SUGGESTION";

export function navbarToggleFolded() {
    return {
        type: TOGGLE_FOLDED_NAVBAR,
    };
}

export function navbarOpenFolded() {
    return {
        type: OPEN_FOLDED_NAVBAR,
    };
}

export function navbarCloseFolded() {
    return {
        type: CLOSE_FOLDED_NAVBAR,
    };
}

export function navbarToggleMobile() {
    return {
        type: TOGGLE_MOBILE_NAVBAR,
    };
}

export function navbarOpenMobile() {
    return {
        type: OPEN_MOBILE_NAVBAR,
    };
}

export function navbarCloseMobile() {
    return {
        type: CLOSE_MOBILE_NAVBAR,
    };
}

export function readSearchSuggestion(searchText) {
    return function(dispatch) {
        axios(apiUrl + "/common/megasearch", {params: {query: searchText}}).then(function(response) {
            if (searchText.toString().substring(0, 3).toUpperCase() === "BAC" && searchText.length >= 7 && !Boolean(response.data.data)) {
                history.push({
                    pathname: "/livraisons/bac/" + searchText,
                });
            }
            dispatch({
                type   : READ_SEARCH_SUGGESTION,
                payload: response.data.data,
            });
        });
    };
}


