import * as Actions        from "../../actions/fuse/index";
import getNavigationConfig from "app/fuse-configs/navigationConfig";

const initialState = {
    navigation      : getNavigationConfig("adminPanel"),
    currentPanel    : "adminPanel",
    currentTruck    : "",
    currentWarehouse: "",

};

function loadState() {
    let initialstate = {};
    const navigationState = localStorage.getItem("navigationState") ? JSON.parse(localStorage.getItem("navigationState")) : initialState;
    const currentWarehouse = localStorage.getItem("currentWarehouse") ? JSON.parse(localStorage.getItem("currentWarehouse")) : "";
    const currentTruck = localStorage.getItem("currentTruck") ? JSON.parse(localStorage.getItem("currentTruck")) : "";
    return {...navigationState,currentWarehouse: currentWarehouse, currentTruck: currentTruck, ...initialstate}
}

const navigation = function(state = loadState(), action) {
    switch (action.type) {

        case Actions.GET_NAVIGATION: {
            return [
                ...state,
            ];
        }

        case Actions.SET_NAVIGATION: {
            let newState = [
                ...state,
                state.navigation[action.navigation],
            ];
            localStorage.setItem("navigationState", JSON.stringify(initialState));
            return newState;
        }

        case Actions.RESET_NAVIGATION: {
            localStorage.setItem("navigationState", JSON.stringify(initialState));
            return [
                ...initialState,
            ];
        }

        case Actions.SET_PANEL: {
            let newState;
            newState = {
                ...state,

                navigation  : getNavigationConfig(action.panel),
                currentPanel: action.panel,
            };
            localStorage.setItem("navigationState", JSON.stringify(newState));
            return newState;
        }

        case Actions.SET_WAREHOUSE: {
            localStorage.setItem("currentWarehouse", JSON.stringify(action.payload));
            let newState;
            newState = {
                ...state,
                currentWarehouse: action.payload
            };
            return newState;
        }

        case Actions.SET_TRUCK : {
            localStorage.setItem("currentTruck", JSON.stringify(action.payload));
            let newState;
            newState = {
                ...state,
                currentTruck: action.payload
            };
            return newState;
        }

        default: {
            return state;
        }
    }
};

export default navigation;
