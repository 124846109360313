import red         from "@material-ui/core/colors/red";

const themeStageConfig = {
    fuse: {
        palette: {
            type      : "light",
            primary   : {
                light       : "#efefe3",
                main        : "#00b300",
                dark        : "#40ff00",
                contrastText: "#000000",
                100         : "#C5C6CB",
                200         : "#75767B",
            },
            secondary : {
                dark        : "#990000",
                main        : "#ff3333",
                light       : "#ff3333",
                contrastText: "#242325",
            },
            background: {
                default  : "#242325",
                secondary: "#f7faff",
                paper    : "#FCFDFF",
            },
            error     : red,
        },
        status : {
            danger: "orange",
        },
    },
};

export default themeStageConfig;
