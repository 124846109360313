import React      from "react";
import Typography from "@material-ui/core/Typography";
import translate  from "app/services/lang/translate";
import Grid       from "@material-ui/core/Grid";

export function invalid_state(errors) {

    return <div className={"max-w-sm"}>
        <Typography className={"text-lg font-semibold"}>
            Impossible de faire le changement à cause de l'état courant.
        </Typography>

        <br/>

        <Typography className={"font-semibold mb-4"}>L'état courant est : {translate(errors.current_state)}</Typography>
        <Grid container className={"mb-8"}>
            <Grid item xs={6}>
                <Typography>Les états valides sont :</Typography>
            </Grid>
            <Grid item container xs={6}>
                {errors.valid_states.map((state,index) => {
                    return <Grid item key={index} container xs={4}>
                        <Typography>{translate(state)}</Typography>
                    </Grid>;
                })}
            </Grid>
        </Grid>
    </div>;
}

