import { AT_LOGIN } from '../actions/actions-types'

function loadState() {
    return {
        success: !!localStorage.getItem('userInfo'),
        error  : {
            username: null,
            password: null,
            panel: null,
        }
    };
}

const login = function (state = loadState(), action) {
    switch ( action.type )
    {
        case AT_LOGIN.LOGIN_SUCCESS:
        {
            state = {
                success: true,
            };
            break;
        }
        case AT_LOGIN.LOGIN_ERROR:
        {
            state = {
                success: false,
                error  : action.payload,
            };
            break;
        }
        case AT_LOGIN.LOGIN_LOGOUT:
        {
            state = {
                success: false,
                error  : null,
            };
            break;
        }
        default:
        {
            return state
        }
    }
    return state;
};

export default login;