import React                              from "react";
import { AppBar, Typography, withStyles } from "@material-ui/core";
import panelConfig                        from "../../fuse-configs/panelConfig";
import AlertDialog                        from "app/main/genericComponents/dialog/AlertDialog";
import translate                          from "app/services/lang/translate";
import { useSelector }                    from "react-redux";
import { FontAwesomeIcon }                from "@fortawesome/react-fontawesome";
import { faHexagon }                      from "@fortawesome/pro-regular-svg-icons";
import { newDateWithTimeZone }            from "app/services/Utils";

const styles = theme => ({
    root            : {
        "& .user": {
            "& .username, & .email": {
                transition: theme.transitions.create("opacity", {
                    duration: theme.transitions.duration.shortest,
                    easing  : theme.transitions.easing.easeInOut,
                }),
            },
        },
    },
    panelButton     : {
        width       : "72px",
        height      : "72px",
        position    : "absolute",
        top         : "75px",
        padding     : "8px",
        background  : theme.palette.background.default,
        boxSizing   : "content-box",
        left        : "50%",
        transform   : "translateX(-50%)",
        borderRadius: "50%",
        borderTop   : "5px solid #08415C",
        borderBottom: "5px solid #ffffff00",
        "&:hover"   : {
            border         : "5px solid #fdba29",
            backgroundColor: theme.palette.background.default,
        },
    },
    panelButtons    : {
        align: "center",
    },
    navigationHeader: {
        paddingBottom: 50,
    },
});

const UserNavbarHeader = ({classes}) => {

    const user         = useSelector(({auth}) => auth.user);
    const currentPanel = useSelector(({fuse}) => fuse.navigation.currentPanel);

    return (
        <AppBar
            position="static"
            color="primary"
            elevation={0}
            classes={{root: classes.root}}
            className={"user relative flex flex-col items-center justify-center z-0 pb-52"}
        >
            <Typography className="username whitespace-no-wrap"
                        color="inherit">{user.data.full_name}</Typography>
            <Typography className="email mt-16 opacity-50 whitespace-no-wrap"
                        color="inherit">Panneau {translate(currentPanel)}</Typography>
            <AlertDialog
                openModalButtonRender={getOpenPanelModalButtonRender(currentPanel)}
                modalButtonPosition={"panelMenuIcon"}
                openModalButtonStyle={classes.panelButton}
                closeModalButtonsStyle={classes.panelButtons}
            />

        </AppBar>
    );
};

function getEaster(year) {
    var f     = Math.floor,
        // Golden Number - 1
        G     = year % 19,
        C     = f(year / 100),
        // related to Epact
        H     = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
        // number of days from 21 March to the Paschal full moon
        I     = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
        // weekday for the Paschal full moon
        J     = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
        // number of days from 21 March to the Sunday on or before the Paschal full moon
        L     = I - J,
        month = 3 + f((L + 40) / 44),
        day   = L + 28 - 31 * f(month / 4);

    return [month, day];
}

function getOpenPanelModalButtonRender(currentPanel) {
    const panel       = Object.values(panelConfig).find((panel) => {
        return panel.name === currentPanel;
    });
    let octogoneColor = "#fdba29";
    let logo          = "";
    const date        = newDateWithTimeZone(new Date());
    const easterDate  = getEaster(date.getFullYear());

    if (date.getMonth() === 11 && date.getDate() <= 28 && date.getDate() >= 1) {
        logo          = panel.logoChristmas;
        octogoneColor = panel.octogoneColorChristmas;
    } else if (date.getMonth() === 9 && date.getDate() <= 31 && date.getDate() >= 24) {
        logo          = panel.logoHalloween;
        octogoneColor = panel.octogoneColorHalloween;
    } else if ((date.getMonth() === 11 && date.getDate() >= 29 && date.getDate() <= 31) || (date.getMonth() === 0 && date.getDate() >= 1 && date.getDate() <=7)) {
        logo          = panel.logoNewYear;
        octogoneColor = panel.octogoneColorNewYear;
    } else if(easterDate[0]-1===date.getMonth() && easterDate[1]-2<=date.getDate() && easterDate[1]+2>=date.getDate()){
        logo          = panel.logoEaster;
        octogoneColor = panel.octogoneColorEaster;
    } else if(date.getMonth()===1 && date.getDate()>=13 && date.getDate()<=14){
        logo          = panel.logoValentine;
        octogoneColor = panel.octogoneColorValentine;
    }else {
        logo = panel.logo;
    }

    return <span className="fa-layers fa-fw fa-4x">
            {logo}
        <FontAwesomeIcon icon={faHexagon} color={octogoneColor}/>
        </span>;
}

export default withStyles(styles, {withTheme: true})(UserNavbarHeader);
