import axios    from "app/services/axios/CustomAxios";
import {apiUrl} from "app/services/axios/configApi";

export function readAllNodes() {
    return axios(apiUrl + "/inventory/nodes");
}

export function createNode(nodeInformation) {
    return axios.post(apiUrl + "/inventory/nodes", {
        "name"       : nodeInformation.name,
        "civic_no"   : nodeInformation.civic_no,
        "odonym"     : nodeInformation.odonym,
        "city"       : nodeInformation.city,
        "postal_code": nodeInformation.postal_code,
        "location"   : nodeInformation.location,
        "gateway_id" : nodeInformation.gateway.id,
    })
        .catch(error => {
            return error.response;
        });
}

export function saveNode(nodeInformation) {
    return axios.put(`${apiUrl + "/inventory/nodes/" + nodeInformation.id}`, {
        "name"       : nodeInformation.name,
        "civic_no"   : nodeInformation.civic_no,
        "odonym"     : nodeInformation.odonym,
        "city"       : nodeInformation.city,
        "postal_code": nodeInformation.postal_code,
        "location"   : nodeInformation.location,
        "gateway_id" : nodeInformation.gateway.id,
    })
        .catch(error => {
            return error.response;
        });
}

export function readNode(id) {
    return axios(apiUrl + "/inventory/nodes/" + id);
}

export function deleteNode(nodeId) {
    return axios.delete(apiUrl + "/inventory/nodes/" + nodeId)
        .catch(error => {
            return error.response;
        });
}