import React       from "react";
import Grid        from "@material-ui/core/Grid";
import Typography  from "@material-ui/core/Typography";
import StockButton from "app/main/genericComponents/buttons/modelButtons/models/StockButton";

export function supply_in_use(errors) {
    return <div className={"max-w-sm"}>
        <Typography className={"text-lg"}>Ce matériaux a des équipements en utilisation.</Typography>
        <Grid container>
            {errors.stocks.slice(0, 10).map(stock => {
                return <Grid item xs={6} className={"p-8 text-center flex"}>
                    <StockButton stock={stock}/>
                </Grid>;
            })}
        </Grid>
    </div>;
}

