import store                 from "app/store";
import * as AppActions       from "app/store/actions";
import * as exceptions       from "app/main/genericComponents/exceptions/exceptions/index";
import React                 from "react";
import Typography            from "@material-ui/core/Typography";
import Grid                  from "@material-ui/core/Grid";
import { faArrowRight }      from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { FontAwesomeIcon }   from "@fortawesome/react-fontawesome";
import translateErrorMessage from "app/services/lang/translateErrorMessage";
import translate             from "app/services/lang/translate";

export function handleException(exception) {
    if (exception.message === "The given data was invalid.") {
        store.dispatch(AppActions.showMessage({
            message         : <div className={"max-w-sm"}>
                <Typography>Les informations suivantes sont invalides</Typography>
                <Grid container>
                    {Object.keys(exception.errors).map((error, index) => {
                        return <React.Fragment key={index}>
                            <Grid item xs={5} className={"text-center"}>
                                <Typography>{translate(error)}</Typography>
                            </Grid>
                            <Grid item xs={2} className={"text-center"}>
                                <FontAwesomeIcon icon={faArrowRight}/>
                            </Grid>
                            <Grid item xs={5} className={"text-center"}>
                                <Typography>{translateErrorMessage(exception.errors[error])}</Typography>
                            </Grid>
                        </React.Fragment>;
                    })}
                </Grid>
            </div>,
            autoHideDuration: 10000,//ms
            anchorOrigin    : {vertical: "bottom", horizontal: "right"},
            variant         : "error",
        }));
    } else if (Boolean(exceptions[exception.message])) {
        store.dispatch(AppActions.showMessage({
            message         : exceptions[exception.message](exception),
            autoHideDuration: 10000,//ms
            anchorOrigin    : {vertical: "bottom", horizontal: "right"},
            variant         : "error",
        }));
    } else {
        store.dispatch(AppActions.showMessage({
            message         : <div>
                <Typography>Erreur inconnue</Typography>
                <Typography>veuillez contacter votre superviseur</Typography>
            </div>,
            autoHideDuration: 5000,//ms
            anchorOrigin    : {vertical: "bottom", horizontal: "right"},
            variant         : "error",
        }));
    }

}