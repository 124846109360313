import React        from "react";
import Typography   from "@material-ui/core/Typography";
import StockButton  from "app/main/genericComponents/buttons/modelButtons/models/StockButton";
import translate    from "app/services/lang/translate";
import SupplyButton from "app/main/genericComponents/buttons/modelButtons/models/SupplyButton";
import Table        from "@material-ui/core/Table";
import TableHead    from "@material-ui/core/TableHead";
import TableCell    from "@material-ui/core/TableCell";
import TableRow     from "@material-ui/core/TableRow";
import TableBody    from "@material-ui/core/TableBody";

export function invalid_mass_move(errors) {
    return <div>
        <Typography>Certains équipements ou matériaux sont invalides.</Typography>

        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography className={"text-white"}>
                            Équipement / Matériel
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography className={"text-white"}>
                            Erreur
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {errors.invalid_items.slice(0, 10)
                       .map((item, index) => {
                           return <TableRow key={index}>
                               {Boolean(item.stock) && (
                                   <TableCell>
                                       <StockButton stock={item.stock}/>
                                   </TableCell>
                               )}

                               {Boolean(item.bundle) && (
                                   <TableCell>
                                       <SupplyButton supply={item.bundle.supply}/>
                                   </TableCell>
                               )}
                               {item.error === "invalid_storage_type" && (
                                   <TableCell>
                                       <Typography className={"text-white"}>
                                           {"L'équipements est dans un " + translate(item.data) + "."}
                                       </Typography>
                                   </TableCell>
                               )}
                               {item.error === "invalid_state" && (
                                   <TableCell>
                                       <Typography className={"text-white"}>
                                           {"L'équipement est " + translate(item.data) + "."}
                                       </Typography>
                                   </TableCell>
                               )}
                               {item.error !== "invalid_state" && item.error !== "invalid_storage_type" && (
                                   <TableCell>
                                       <Typography className={"text-white"}>
                                           {item.error + " " + item.data}
                                       </Typography>
                                   </TableCell>
                               )}
                           </TableRow>;
                       })}
            </TableBody>
        </Table>
    </div>;
}
