import axios                    from "../../../services/axios/CustomAxios";
import {updateUserData}         from "./user.actions";
import {apiUrl, apiCredentials} from "app/services/axios/configApi";
import history                  from "@history.jsx";
import {AT_LOGIN}               from "./actions-types";
import panelConfig              from "app/fuse-configs/panelConfig";
import {setPanel}               from "app/store/actions/fuse";
import * as Actions             from "app/store/actions";

export function submitLogin(email, password, currentPanel, redirectUrl) {
    return (dispatch) => {

        let data = new FormData();
        data.append("grant_type", "password");
        data.append("client_id", apiCredentials.clientID);
        data.append("client_secret", apiCredentials.clientSecret);
        data.append("username", email);
        data.append("password", password);
        data.append("scope", "");

        axios.post(apiUrl + "/oauth/token", data).then(response => {

            if (response.data) {

                let userData = {
                    auth_token   : response.data.access_token,
                    expires_in   : response.data.expires_in,
                    token_type   : response.data.token_type,
                    refresh_token: response.data.refresh_token,
                };

                let access_token = {
                    isLoggedIn: true,
                    user      : userData,
                };

                setSession(access_token);

                dispatch(updateUserData(() => {
                    const panels = Object.values(panelConfig)
                    let panel = panels.find((panel) => {
                        return panel.name === currentPanel;
                    });
                    let userHasPermission = false;
                    if (localStorage.getItem("userInfo")) {
                        const userPermissions = JSON.parse(localStorage.getItem("userInfo")).permissions;
                        if (userPermissions.length === 1 && userPermissions[0].code === "contractor_panel") {
                            panel = panels.find(panel => {
                                return panel.name === "contractorPanel"
                            })
                        }
                        userHasPermission = JSON.parse(localStorage.getItem("userInfo")).permissions.find(function (permission) {
                            return permission.code === panel.permission;
                        });
                    }
                    if (userHasPermission || panel.name === "contractorPanel") {
                        history.push(redirectUrl);
                        dispatch(setPanel(panel.name));
                        return;
                    }
                    history.push({pathname: "/invalid-panel",});
                }));

                return dispatch({
                                    type: AT_LOGIN.LOGIN_SUCCESS,
                                });

            } else {
                return response.data.error;
            }
        }).catch(error => {
            if (error.message === "Request failed with status code 400") {
                return dispatch(
                    Actions.showMessage({
                                            message         : "Mauvais courriel ou mot de passe",//text or html
                                            autoHideDuration: 5000,//ms
                                            anchorOrigin    : {
                                                vertical  : "top",//top bottom
                                                horizontal: "center",//left center right
                                            },
                                            variant         : "error",//success error info warning null
                                        }));
            } else {
                return dispatch(
                    Actions.showMessage({
                                            message         : "Erreur du logiciel veuillez contacter votre superviseur",//text or html
                                            autoHideDuration: 5000,//ms
                                            anchorOrigin    : {
                                                vertical  : "top",//top bottom
                                                horizontal: "center",//left center right
                                            },
                                            variant         : "error",//success error info warning null
                                        }));
            }
        });
    };
}

export function setSession(access_token) {
    if (access_token) {
        localStorage.setItem("access_token", JSON.stringify(access_token));
    } else {
        localStorage.removeItem("access_token");
    }
}

export function logoutUser() {
    localStorage.clear();
    return (dispatch) => {
        history.push({
                         pathname: "/login",
                     });
        dispatch({
                     type: AT_LOGIN.LOGIN_LOGOUT,
                 });
        window.location.reload();
    };
}