import React             from "react";

export const LoginConfig = {
    settings: {
        layout: {
            config: {
                navbar        : {
                    display: false,
                },
                toolbar       : {
                    display: false,
                },
                footer        : {
                    display: false,
                },
                leftSidePanel : {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    routes  : [

        //auth
        {
            path     : "/mot-de-passe-oublie",
            component  : React.lazy(() => import("app/main/components/auth/login/forgottenPassword/ForgotPassword")),
            authFree : true,
        },
        {
            path     : "/reinitialiser/:token",
            component  : React.lazy(() => import("app/main/components/auth/login/resetPassword/ResetPassword")),
            authFree : true,
        },
        {
            path     : "/login-sous-traitant",
            component  : React.lazy(() => import("app/main/components/auth/login/loginPageContractor/LoginContractor")),
            authFree : true,
        },
        {
            path     : "/login",
            component  : React.lazy(() => import("app/main/components/auth/login/Login")),
            authFree : true,
        },

        //errorPage
        {
            path       : "/erreur500",
            component  : React.lazy(() => import("app/main/components/auth/errorPages/Error500")),
            permissions: ["super_admin"],
        },
        {
            path     : "/invalid-panel",
            component  : React.lazy(() => import("app/main/components/auth/errorPages/InvalidPanel")),
            authFree : true,
        },
        {
            path     : "/error-403",
            component  : React.lazy(() => import("app/main/components/auth/errorPages/Error403Page")),
            authFree : true,
        },
        {
            path     : "/error-404",
            component  : React.lazy(() => import("app/main/components/auth/errorPages/Error404Page")),
            authFree : true,
        },

        //conseils
        {
            path     : "/conseils/presentation",
            component  : React.lazy(() => import("app/main/components/auth/tips/showTips/PresentTips")),
            permissions: ["display_tips"],
        },

    ],
};
