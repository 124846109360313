import axios              from "app/services/axios/CustomAxios";
import { apiUrl }         from "app/services/axios/configApi";
import { asyncGetImages } from "app/services/imageTools";

export function createFeedback(feedbackInformation) {
    var formData = new FormData();
    return asyncGetImages(feedbackInformation.files)
        .then((images) => {
            formData.append("message", feedbackInformation.message);
            images.forEach((image, index) => {
                formData.append("pictures[" + index + "]", image);
            });
        })
        .then(() => axios.post(`${apiUrl + "/common/feedbacks"}`, formData,
            {headers: {"Content-Type": "multipart/form-data"}}))
        .catch(error => {
            return error.response;
        });
}

export function readAllFeedbacks() {
    return axios(apiUrl + "/common/feedbacks");
}

export function deleteFeedback(feedbackId) {
    return axios.delete(apiUrl + "/common/feedbacks/" + feedbackId)
                .catch(error => {
                    return error.response;
                });
}