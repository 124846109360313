import * as Actions            from "app/store/actions";
import store                   from "app/store";
import {handleException}       from "app/main/genericComponents/exceptions/HandleExceptions";
import {handleSuccessMessages} from "app/main/genericComponents/successMessage/HandleSuccessMessages";

export function showApiMessage(response, errorMessage404 = "Élément non trouvé") {
    switch (response.status) {
        case 422:
            handleException(JSON.parse(response.request.response));
            return false;
        case 404:
            store.dispatch(Actions.showMessage({
                                                   message         : errorMessage404,//text or html
                                                   autoHideDuration: 5000,//ms
                                                   anchorOrigin    : {
                                                       vertical  : "bottom",//top bottom
                                                       horizontal: "right",//left center right
                                                   },
                                                   variant         : "error",//success error info warning null
                                               }));
            return true;
        case 200:
            handleSuccessMessages(JSON.parse(response.request.response));
            return true;
        case 500:
            store.dispatch(Actions.showMessage({
                                                   message         : "Erreur du logiciel, veuillez contacter votre superviseur",//text or html
                                                   autoHideDuration: 5000,//ms
                                                   anchorOrigin    : {
                                                       vertical  : "bottom",//top bottom
                                                       horizontal: "right",//left center right
                                                   },
                                                   variant         : "error",//success error info warning null
                                               }));
            return true;
        default :
            return true;
    }
}

export function isApiCallSuccessful(response) {

    switch (response.status) {
        case 422:
        case 404:
        case 500:
            return false;
        case 200:
            return true;
        default :
            return false;
    }
}
