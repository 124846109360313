import React                         from "react";
import { ListItem, ListItemText }    from "@material-ui/core";
import { makeStyles }                from "@material-ui/styles";
import { NavLinkAdapter, FuseUtils } from "@fuse";
import { withRouter }                from "react-router-dom";
import clsx                          from "clsx";
import PropTypes                     from "prop-types";
import { useDispatch, useSelector }  from "react-redux";
import * as Actions                  from "app/store/actions";
import FuseNavBadge                  from "./../FuseNavBadge";
import * as icons                    from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }           from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(theme => ({
    item: {
        height                : 40,
        width                 : "calc(100% - 16px)",
        borderRadius          : "0 20px 20px 0",
        paddingRight          : 12,
        "&.active"            : {
            backgroundColor            : theme.palette.secondary.main,
            color                      : theme.palette.secondary.contrastText + "!important",
            transition                 : "border-radius .15s cubic-bezier(0.4,0.0,0.2,1)",
            "& .list-item-text-primary": {
                color: "inherit",
            },
            "& .list-item-icon"        : {
                color: "inherit",
            },
        },
        "& .list-item-icon"   : {},
        "& .font-awesome-icon": {
            width: 30,
        },
        "& .list-item-text"   : {},
        color                 : theme.palette.background.paper,
        cursor                : "pointer",
        textDecoration        : "none!important",
    },
}));

function FuseNavVerticalItem(props) {
    const dispatch = useDispatch();
    const userRole = useSelector(({auth}) => auth.user.role);

    const classes             = useStyles(props);
    const {item, nestedLevel} = props;
    let paddingValue          = 40 + (nestedLevel * 4);
    const listItemPadding     = nestedLevel > 0 ? "pl-" + (paddingValue > 80 ? 80 : paddingValue) : "pl-16";

    if (! FuseUtils.hasPermission(item.auth, userRole)) {
        return null;
    }

    return (
        <ListItem
            button
            component={NavLinkAdapter}
            to={item.url}
            activeClassName="active"
            className={clsx(classes.item, listItemPadding, "list-item")}
            onClick={ev => dispatch(Actions.navbarCloseMobile())}
            exact={item.exact}
        >
            {item.icon && (
                <FontAwesomeIcon icon={icons[item.icon]} className={"font-awesome-icon mr-8"}/>
            )}
            <ListItemText className="list-item-text" primary={item.title}
                          classes={{primary: "text-16 list-item-text-primary"}}/>
            {item.badge && (
                <FuseNavBadge badge={item.badge}/>
            )}
        </ListItem>
    );
}

FuseNavVerticalItem.propTypes = {
    item: PropTypes.shape(
        {
            id   : PropTypes.string.isRequired,
            title: PropTypes.string,
            icon : PropTypes.string,
            url  : PropTypes.string,
        }),
};

FuseNavVerticalItem.defaultProps = {};

const NavVerticalItem = withRouter(React.memo(FuseNavVerticalItem));

export default NavVerticalItem;
