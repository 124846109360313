import React, { Component }   from "react";
import Button                 from "@material-ui/core/Button";
import * as NavigationActions from "app/store/actions";
import { connect }            from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon }    from "@fortawesome/react-fontawesome";
import { faTruck }            from "@fortawesome/pro-solid-svg-icons/faTruck";
import { faChevronDown }      from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import ControlledDialog       from "app/main/genericComponents/dialog/ControlledDialog";
import { readTruck }          from "app/services/axios/Inventory/truck";
import StorageableSelectField from "app/main/genericComponents/formField/SelectFields/inventorySelectField/StorageableSelectField";
import { withStyles }         from "@material-ui/styles";
import history                from "@history";

const styles = ({
    dialogPosition   : {
        textAlign: "center",
    },
    selectTruckButton: {
        maxWidth    : 265,
        marginBottom: 20,
    },
});

class SelectTruckDialog extends Component {

    constructor(props) {
        super(props);
        this.state              = {
            open        : false,
            selected    : "",
            currentTruck: {},
        };
        this.handleSubmit       = this.handleSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleClose        = this.handleClose.bind(this);
        this.handleOpen         = this.handleOpen.bind(this);
    }

    componentDidMount() {
        if ((this.props.user.data) && this.props.user.data.permissions.find(function(permission) {
            return permission.code === "display_truck";
        })) {
            this.setState({open: !this.hasCurrentTruck()});
            if (this.hasCurrentTruck()) {
                readTruck(this.props.currentTruck.id)
                    .then((response) => this.setState({currentTruck: response.data.data}));
            }
        }

    }

    hasCurrentTruck() {
        return Boolean(this.props.currentTruck.id) && Boolean(localStorage.getItem("currentTruck"));
    }

    handleClose() {
        if (this.props.currentTruck.id) {
            this.setState({open: false});
        }
    }

    handleOpen() {
        this.setState({open: true});
    }

    handleSubmit() {
        if (this.state.selected.id) {
            readTruck(this.state.selected.id)
                .then((response) => {
                    this.setState({open: false, currentTruck: response.data.data});
                    this.props.setTruck({id: this.state.selected.id, name: response.data.data.name});
                });

            history.push("/tableau-de-bord");
        }
    }

    handleSelectChange(event) {
        this.setState({selected: event.value});
    }

    renderButton(menuIsFolded) {

        if (menuIsFolded && this.state.currentTruck.id !== undefined) {

            const currentTruck = this.state.currentTruck;

            if (currentTruck) {
                return <Button
                    color={"secondary"}
                    variant={"contained"}
                    className={this.props.classes.selectTruckButton}
                >
                    <FontAwesomeIcon className={"mr-4"} icon={faTruck}/>
                    {currentTruck.name}
                    <FontAwesomeIcon className={"ml-4"} icon={faChevronDown}/>

                </Button>;
            }
        }

        return <Button color={"secondary"} variant={"contained"}>
            <FontAwesomeIcon icon={faTruck}/>
        </Button>;
    }

    render() {
        if (this.props.currentPanel === "truckPanel") {
            return (
                <div className={this.props.classes.dialogPosition}>
                    <div onClick={this.handleOpen}>
                        {this.renderButton(this.props.isMenuFolded)}
                    </div>

                    <ControlledDialog
                        open={this.state.open}
                        title={"Veuiller choisir votre camion"}
                        content={
                            <StorageableSelectField
                                value={this.state.selected}
                                withDefaultValue={false}
                                storageableType={"truck"}
                                onChange={(event) => this.setState({selected: event.value})}
                            />
                        }
                        handleClose={this.handleClose}
                        handleSubmit={this.handleSubmit}
                    />
                </div>
            );
        }
        return null;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setTruck: NavigationActions.setTruck}, dispatch);
}

function mapStateToProps({fuse, auth}) {
    return {
        currentPanel: fuse.navigation.currentPanel,
        currentTruck: fuse.navigation.currentTruck,
        isMenuFolded: fuse.navbar.foldedOpen,
        user        : auth.user,
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SelectTruckDialog));