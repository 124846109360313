import React               from "react";
import { Typography }      from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import history             from "@history";
import NotificationBase    from "app/main/genericComponents/notification/NotificationBase";
import { faTruckLoading }  from "@fortawesome/pro-solid-svg-icons/faTruckLoading";

export function NewResponsibleForDelivery(props) {

    return (
        <NotificationBase
            content={<Typography className={"align-middle"}>
                <FontAwesomeIcon icon={faTruckLoading}/>
                {" Vous êtes le nouveau responsable de la livraison " + props.infos.delivery_no + "."}
            </Typography>}
            onRedirect={() => history.push("/livraisons/" + props.infos.delivery_id)}
            notification={props}
        />
    );
}