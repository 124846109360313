import React          from "react";
import { withStyles } from "@material-ui/core";
import classNames     from "classnames";
import atomboLogo           from "assets/images/logos/atombo.png";

const styles = theme => ({
    root      : {
        "& .logo-icon": {
            width     : 24,
            height    : 24,
            transition: theme.transitions.create(["width", "height"], {
                duration: theme.transitions.duration.shortest,
                easing  : theme.transitions.easing.easeInOut,
            }),
        },
    },
    reactBadge: {
        backgroundColor: "rgba(0,0,0,0.6)",
        color          : "#61dafb",
    },
});

function Logo({classes}) {
    return (
        <div className={classNames(classes.root, " flex")}>
            <img className="logo" src={atomboLogo} alt="logo"/>
        </div>
    );
}

export default withStyles(styles, {withTheme: true})(Logo);
