import React, {useEffect, useReducer, useRef} from "react";
import {
    Popper,
    ClickAwayListener,
    MenuItem,
    Icon,
    IconButton,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
    Tooltip,
    Typography,
}                                             from "@material-ui/core";
import {makeStyles}                           from "@material-ui/styles";
import {useSelector}                          from "react-redux";
import {FuseUtils}                            from "@fuse";
import clsx                                   from "clsx";
import match                                  from "autosuggest-highlight/match";
import parse                                  from "autosuggest-highlight/parse";
import {withRouter}                           from "react-router-dom";
import Autosuggest                            from "app/main/genericComponents/megaSearch/dist/Autosuggest";
import history                                from "@history";
import {FontAwesomeIcon}                      from "@fortawesome/react-fontawesome";
import * as icons                             from "@fortawesome/pro-regular-svg-icons";
import {megaSearch}                           from "app/services/axios/Common/megaSearch";
import connect                                from "react-redux/es/connect/connect";

const useStyles = makeStyles(theme => ({
    root                    : {},
    container               : {
        position: "relative",
    },
    suggestionsContainerOpen: {
        position : "absolute",
        zIndex   : 1,
        marginTop: theme.spacing(),
        left     : 0,
        right    : 0,
    },
    suggestion              : {
        display: "block",
    },
    suggestionsList         : {
        margin       : 0,
        padding      : 0,
        listStyleType: "none",
    },
    input                   : {
        transition: theme.transitions.create(["background-color"], {
            easing  : theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.short,
        }),
        "&:focus" : {
            backgroundColor: theme.palette.background.paper,
        },
    },
}));

const initialState = {
    searchText   : "",
    search       : false,
    navigation   : null,
    suggestions  : [],
    noSuggestions: false,
    timeout      : "",
};

function renderInputComponent(inputProps) {
    const {
        variant,
        classes, inputRef = () => {
        }, ref, ...other
    } = inputProps;
    return (
        <div className="w-full relative">
            {variant === "basic" ? (
                                     // Outlined
                                     <React.Fragment>
                                         <TextField
                                             fullWidth
                                             InputProps={{
                                                 inputRef: node => {
                                                     ref(node);
                                                     inputRef(node);
                                                 },
                                                 classes : {
                                                     input         : clsx(classes.input, "py-0 px-16 h-48 pr-48"),
                                                     notchedOutline: "rounded-8",
                                                 },
                                             }}
                                             variant="outlined"
                                             {...other}
                                         />
                                         <Icon className="absolute top-0 right-0 h-48 w-48 p-12 pointer-events-none"
                                               color="action">search</Icon>
                                     </React.Fragment>
                                 )
                                 :
             (
                 // Standard
                 <TextField
                     fullWidth
                     InputProps={{
                         disableUnderline: true,
                         inputRef        : node => {
                             ref(node);
                             inputRef(node);
                         },
                         classes         : {
                             input: clsx(classes.input, "py-0 px-16 h-64"),
                         },
                     }}
                     variant="standard"
                     {...other}
                 />
             )}
        </div>
    );
}

function renderIcon(suggestion) {
    switch (suggestion.type) {
        case "stock":
            return <FontAwesomeIcon icon={icons["faBarcodeAlt"]}/>;
        case "supply_emplacement":
            return <FontAwesomeIcon icon={icons["faInventory"]}/>;
        case "delivery":
            return <FontAwesomeIcon icon={icons["faTruckLoading"]}/>;
        case "customer":
            return <FontAwesomeIcon icon={icons["faUsers"]}/>;
        case "repair":
            return <FontAwesomeIcon icon={icons["faScrewdriverWrench"]}/>;
        case "sd_work_order":
            return <FontAwesomeIcon icon={icons["faBriefcase"]}/>;
        default:
            return <FontAwesomeIcon icon={icons[suggestion.icon]}/>;
    }
}

function renderSuggestion(suggestion, {query, isHighlighted}) {
    const matches = match(suggestion.title, query);
    const parts = parse(suggestion.title, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <ListItemIcon>
                {renderIcon(suggestion)}
            </ListItemIcon>
            <ListItemText
                className="pl-0"
                primary={
                    parts.map((part, index) => {
                        return part.highlight ? (
                            <span key={String(index)} style={{fontWeight: 600}}>
                                {part.text}
                            </span>
                        ) : (
                                   <strong key={String(index)} style={{fontWeight: 300}}>
                                       {part.text}
                                   </strong>
                               );
                    })
                }

            />
            <strong style={{fontWeight: 300}}>
                {(suggestion.description) ? suggestion.description : "Navigation"}
            </strong>
        </MenuItem>
    );
}

function getSuggestionValue(suggestion) {
    return suggestion.title;
}

function reducer(state, action) {
    switch (action.type) {
        case "open": {
            return {
                ...state,
                opened: true,
            };
        }
        case "close": {
            return {
                ...state,
                opened    : false,
                searchText: "",
            };
        }
        case "setSearchText": {
            return {
                ...state,
                searchText: action.value,
            };
        }
        case "setNavigation": {
            return {
                ...state,
                navigation: action.value,
            };
        }
        case "updateSuggestions": {
            const suggestions = action.suggestions;
            const isInputBlank = action.value.trim() === "";

            const noSuggestions = !isInputBlank && suggestions.length === 0;

            return {
                ...state,
                suggestions,
                noSuggestions,
            };
        }
        case "clearSuggestions": {
            return {
                ...state,
                suggestions  : [],
                noSuggestions: false,
            };
        }
        case "decrement": {
            return {count: state.count - 1};
        }
        case "setTimeout": {
            return {
                ...state,
                timeout: action.value,
            };
        }
        default: {
            throw new Error();
        }
    }
}

function FuseSearch(props) {
    const userRole = useSelector(({auth}) => auth.user.role);
    const navigation = useSelector(({fuse}) => fuse.navigation);

    const [state, dispatch] = useReducer(reducer, initialState);
    const classes = useStyles(props);
    const suggestionsNode = useRef(null);
    const popperNode = useRef(null);

    useEffect(() => {
        function itemAuthAllowed(item) {
            return FuseUtils.hasPermission(item.auth, userRole);
        }

        function setNavigation() {
            dispatch({
                         type : "setNavigation",
                         value: FuseUtils.getFlatNavigation(navigation).filter(item => itemAuthAllowed(item)),
                     });
        }

        setNavigation();
    }, [userRole, navigation]);

    function showSearch() {
        dispatch({type: "open"});
        document.addEventListener("keydown", escFunction, false);
    }

    function hideSearch() {
        dispatch({type: "close"});
        document.removeEventListener("keydown", escFunction, false);
    }

    function escFunction(event) {
        if (event.keyCode === 27) {
            hideSearch();
        }
    }

    function handleSuggestionsFetchRequested({value}) {
        clearTimeout(state.timeout);
        if ((value.substring(0, 3).toUpperCase() === "C00" && value.length >= 5) || (value.substring(0, 3).toUpperCase() !== "C00" && value.length >= 3)) {
            const timeout = setTimeout(() => fetchSuggestion(value), 800);
            dispatch({
                         type : "setTimeout",
                         value: timeout,
                     });
        }
    }

    function fetchSuggestion(value) {
        const upperValue = value.toUpperCase();
        megaSearch(upperValue).then(function (response) {
            if (upperValue.toString().substring(0, 3) === "BAC" && upperValue.length >= 7 && response.data.data.length === 0) {
                return history.push({
                                        pathname: "/livraisons/bac/" + upperValue,
                                    });
            } else {
                return dispatch({
                                    type       : "updateSuggestions",
                                    value      : value,
                                    suggestions: response.data.data,
                                });
            }
        });
    }

    function handleSuggestionSelected(event, {suggestion}) {
        switch (suggestion.type) {
            case "stock":
                history.push({
                                 pathname: "/equipements/" + suggestion.id,
                             });
                break;
            case "delivery":
                history.push({
                                 pathname: "/livraisons/" + suggestion.id,
                             });
                break;
            case "supply_emplacement":
                history.push({
                                 pathname: "/emplacements/" + suggestion.id,
                             });
                break;
            case "customer":
                history.push({
                                 pathname: "/clients/" + suggestion.title,
                             });
                break;
            case "repair":
                history.push({
                                 pathname: "/reparations/" + suggestion.id,
                             });
                break;
            case "sd_work_order":
                history.push({
                                 pathname: "/drops-sur-demande/" + suggestion.id,
                             });
                break;
            default:
                break;
        }
        hideSearch();
    }

    function handleSuggestionsClearRequested() {
        dispatch({
                     type: "clearSuggestions",
                 });
    }

    function handleChange(event) {
        dispatch({
                     type : "setSearchText",
                     value: event.target.value,
                 });
    }

    function handleClickAway(event) {
        return (
                   !suggestionsNode.current ||
                   !suggestionsNode.current.contains(event.target)
               ) && hideSearch();
    }

    const autosuggestProps = {
        renderInputComponent,
        highlightFirstSuggestion   : true,
        suggestions                : state.suggestions,
        onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: handleSuggestionsClearRequested,
        onSuggestionSelected       : handleSuggestionSelected,
        getSuggestionValue,
        renderSuggestion,
    };

    if (props.currentPanel !== "contractorPanel") {
        switch (props.variant) {
            case "basic": {
                return (
                    <div className={clsx("flex items-center w-full", props.className)} ref={popperNode}>
                        <Autosuggest
                            {...autosuggestProps}
                            inputProps={{
                                variant        : props.variant,
                                classes,
                                placeholder    : "Search",
                                value          : state.searchText,
                                onChange       : handleChange,
                                onFocus        : showSearch,
                                InputLabelProps: {
                                    shrink: true,
                                },
                                autoFocus      : false,
                            }}
                            theme={{
                                container      : "flex flex-1 w-full",
                                suggestionsList: classes.suggestionsList,
                                suggestion     : classes.suggestion,
                            }}
                            renderSuggestionsContainer={options => (
                                <Popper
                                    anchorEl={popperNode.current}
                                    open={Boolean(options.children) || state.noSuggestions}
                                    popperOptions={{positionFixed: true}}
                                    className="z-9999"
                                >
                                    <div ref={suggestionsNode}>
                                        <Paper
                                            elevation={1}
                                            square
                                            {...options.containerProps}
                                            style={{width: popperNode.current ? popperNode.current.clientWidth : null}}
                                        >
                                            {options.children}
                                            {state.noSuggestions && (
                                                <Typography className="px-16 py-12">
                                                    No results..
                                                </Typography>
                                            )}
                                        </Paper>
                                    </div>
                                </Popper>
                            )}
                        />
                    </div>
                );
            }
            case "full": {
                return (
                    <div className={clsx(classes.root, "flex", props.className)}>

                        <Tooltip title="Click pour rechercher" placement="bottom">
                            <div onClick={showSearch}>
                                {props.trigger}
                            </div>
                        </Tooltip>

                        {state.opened && (
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <Paper
                                    className="absolute left-0 right-0 h-full z-9999"
                                    square={true}
                                >
                                    <div className="flex items-center w-full" ref={popperNode}>
                                        <Autosuggest
                                            {...autosuggestProps}
                                            inputProps={{
                                                classes,
                                                placeholder    : "Recherche",
                                                value          : state.searchText,
                                                onChange       : handleChange,
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                                autoFocus      : true,
                                            }}
                                            theme={{
                                                container      : "flex flex-1 w-full",
                                                suggestionsList: classes.suggestionsList,
                                                suggestion     : classes.suggestion,
                                            }}
                                            renderSuggestionsContainer={options => (
                                                <Popper
                                                    anchorEl={popperNode.current}
                                                    open={Boolean(options.children) || state.noSuggestions}
                                                    popperOptions={{positionFixed: true}}
                                                    className="z-9999"
                                                >
                                                    <div ref={suggestionsNode}>
                                                        <Paper
                                                            elevation={1}
                                                            square
                                                            {...options.containerProps}
                                                            style={{width: popperNode.current ? popperNode.current.clientWidth : null}}
                                                        >
                                                            {options.children}
                                                            {state.noSuggestions && (
                                                                <Typography className="px-16 py-12">
                                                                    No results..
                                                                </Typography>
                                                            )}
                                                        </Paper>
                                                    </div>
                                                </Popper>
                                            )}
                                        />
                                        <IconButton onClick={hideSearch} className="mx-8">
                                            <Icon>close</Icon>
                                        </IconButton>
                                    </div>
                                </Paper>
                            </ClickAwayListener>
                        )}
                    </div>
                );
            }
            default : {
                return null;
            }
        }
    }
    return <></>
}

FuseSearch.propTypes = {};
FuseSearch.defaultProps = {
    trigger: (<><IconButton className="h-64">Recherche &nbsp;&nbsp; <Icon>search</Icon></IconButton></>),
    variant: "full",// basic, full
};


function mapStateToProps({fuse}) {
    return {
        currentPanel: fuse.navigation.currentPanel
    };
}

export default (connect(mapStateToProps)(withRouter(React.memo(FuseSearch))));
