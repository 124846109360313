import React       from "react";
import StockButton from "app/main/genericComponents/buttons/modelButtons/models/StockButton";
import Grid        from "@material-ui/core/Grid";
import Typography     from "@material-ui/core/Typography";

export function transmitter_in_use(errors) {
    return <div className={"max-w-sm"}>

        <div className={"flex items-center "}>
        <Typography>{"Le transmetteur est en utilisation."}</Typography>
        </div>
        <Grid container>
            {errors.receivers.slice(0, 10).map(receiver => {
                return <Grid item xs={6} key={receiver.id} className={"p-8 text-center flex"}>
                    <StockButton stock={receiver.stock}/>
                </Grid>;
            })}
        </Grid>
    </div>;
}
