import React, { Component }        from "react";
import { FontAwesomeIcon }         from "@fortawesome/react-fontawesome";
import { faWarehouseAlt }          from "@fortawesome/pro-solid-svg-icons";
import { faTruck }                 from "@fortawesome/pro-solid-svg-icons/faTruck";
import { faTruckLoading }          from "@fortawesome/pro-solid-svg-icons/faTruckLoading";
import { faBullseye, faDotCircle } from "@fortawesome/free-solid-svg-icons";

class StorageableIcon extends Component {

    render() {
        switch (this.props.storageableType) {
            case"warehouse" :
                return <FontAwesomeIcon size={this.props.size} icon={faWarehouseAlt} className={this.props.className}/>;

            case"truck" :
                return <FontAwesomeIcon size={this.props.size} icon={faTruck} className={this.props.className}/>;

            case"delivery" :
                return <FontAwesomeIcon size={this.props.size} icon={faTruckLoading} className={this.props.className}/>;

            case"node" :
                return <FontAwesomeIcon size={this.props.size} icon={faDotCircle} className={this.props.className}/>;

            case"cell" :
                return <FontAwesomeIcon size={this.props.size} icon={faBullseye} className={this.props.className}/>;

            default :
                return <FontAwesomeIcon size={this.props.size} icon={faWarehouseAlt} className={this.props.className}/>;
        }
    }
}

export default (StorageableIcon);
