import React        from "react";

export const AuthConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes  : [
        //admin
        {
            path       : "/administrateurs/creer",
            component  : React.lazy(() => import("app/main/components/auth/administrator/createAdmin/CreateAdmin")),
            permissions: ["super_admin"],
        },
        {
            path       : "/administrateurs/:id",
            component  : React.lazy(() => import("app/main/components/auth/administrator/updateAdmin/UpdateAdmin")),
            permissions: ["super_admin"],
        },
        {
            path       : "/administrateurs",
            component  : React.lazy(() => import("app/main/components/auth/administrator/showAdmins/AdminsList")),
            permissions: ["super_admin"],
        },
        {
            path       : "/responsable-drop",
            component  : React.lazy(() => import("app/main/components/auth/administrator/showResponsibleDrop/ShowDropResponsible")),
            permissions: ["super_admin"],
        },
        {
            path       : "/responsable-reparation",
            component  : React.lazy(() => import("app/main/components/auth/administrator/showRepairResponsible/ShowRepairResponsible")),
            permissions: ["super_admin"],
        },

        //Role
        {
            path       : "/roles/creer",
            component  : React.lazy(() => import("app/main/components/auth/role/createRole/CreateRole")),
            permissions: ["super_admin"],
        },
        {
            path       : "/roles/:id",
            component  : React.lazy(() => import("app/main/components/auth/role/updateRole/UpdateRole")),
            permissions: ["super_admin"],
        },

        //dashboard
        {
            path: '/tableau-de-bord',
            component:  React.lazy(() => import('app/main/components/auth/dashboard/DashboardDefault')),
            permissions: []
        },

        //suggestion
        {
            path       : "/suggestions",
            component  :  React.lazy(() => import("app/main/components/auth/feedback/showFeedbacks/ShowFeedbacks")),
            permissions: ["display_supply"],
        },
        //conseils
        {
            path       : "/conseils",
            component  :  React.lazy(() => import("app/main/components/auth/tips/showTips/ShowTips")),
            permissions: ["display_tips"],
        },
    ],
};