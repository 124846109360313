import React             from "react";
import Grid              from "@material-ui/core/Grid";
import Typography        from "@material-ui/core/Typography";
import EmplacementButton from "app/main/genericComponents/buttons/modelButtons/models/EmplacementButton";

export function supplies_in_storage(errors) {
    return <div className={"max-w-sm"}>
        <Typography className={"text-lg"}>Des emplacements de l'entrepôt contiennent des matériaux.</Typography>
        <Typography>Veuillez les retirer pour continuer.</Typography>
        <Grid container>
            {errors.emplacements.slice(0, 10).map(emplacement => {
                return <Grid item xs={6} className={"p-8 text-center flex"}>
                    <EmplacementButton emplacement={emplacement}/>
                </Grid>;
            })}
        </Grid>
    </div>;
}

