import React      from "react";
import Grid       from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export function has_channel_locked(errors) {
    return <div className={"max-w-sm"}>
        <Typography>Le client a les chaines suivantes barrées.</Typography>
        <Typography>Veuillez vérifier la pertinence de la fermeture du service en moins d'un mois.</Typography>

        {errors.channel_locks.slice(0, 10)
               .map(lock => {
                   return <Grid container>
                       <Grid item xs={6}>
                           {lock.channel.name_fr}
                       </Grid>
                       <Grid item xs={6}>
                           {"Date fin : " + lock.end_date}
                       </Grid>
                   </Grid>;
               })}

    </div>;
}
