import React from "react";

export const WorkOrderConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes  : [
        //contractor
        {
            path       : "/sous-traitants/:id",
            component  : React.lazy(() => import("app/main/components/workOrder/contractor/contractor/ShowContractor")),
            permissions: ["display_SD_drops"],
        },
        {
            path       : "/sous-traitants",
            component  : React.lazy(() => import("app/main/components/workOrder/contractor/ShowContractors")),
            permissions: ["display_SD_drops"],
        },

        //contractorDemand
        {
            path       : "/tache-externes",
            component  : React.lazy(() => import("app/main/components/workOrder/contractorDemand/showContractorDemands/ContractorDemands")),
            permissions: ["display_repairs", "display_SD_drops"],
        },

        //contractorPanel
        {
            path       : "/Services-sur-demande",
            component  : React.lazy(() => import("app/main/components/workOrder/contractorPanel/showContractorDemands/ContractorsDemands")),
            permissions: ["contractor_panel"],
        },

        //repair
        {
            path       : "/reparations-attente-completion",
            component  : React.lazy(() => import("app/main/components/workOrder/repairs/ShowRepairs/WaitingCompletionRepairs")),
            permissions: ["display_repairs"],
        },
        {
            path       : "/reparations-attente-validation",
            component  : React.lazy(() => import("app/main/components/workOrder/repairs/ShowRepairs/WaitingValidationRepairs")),
            permissions: ["display_repairs"],
        },
        {
            path       : "/reparations/creer",
            component  : React.lazy(() => import("app/main/components/workOrder/repairs/CreateRepair/CreateRepairPage")),
            permissions: ["display_repairs"],
        },
        {
            path       : "/reparations/uuid/:id",
            component  : React.lazy(() => import("app/main/components/workOrder/repairs/showRepairUUID/ShowRepairUUID")),
            permissions: ["display_repairs"],
        },
        {
            path       : "/reparations/sous-traitant/:id",
            component  : React.lazy(() => import("app/main/components/workOrder/repairs/showRepairId/ShowRepairId")),
            permissions: ["contractor_panel"],
        },
        {
            path       : "/reparations/:id",
            component  : React.lazy(() => import("app/main/components/workOrder/repairs/showRepairId/ShowRepairId")),
            permissions: ["display_repairs"],
        },
        {
            path       : "/reparations",
            component  : React.lazy(() => import("app/main/components/workOrder/repairs/ShowRepairs/Repairs")),
            permissions: ["display_repairs"],
        },

        //workOrder
        {
            path       : "/drops-sur-demande/sous-traitant/:id",
            component  : React.lazy(() => import("app/main/components/workOrder/sdWorkOrder/ShowWorkOrder/SDWorkOrder")),
            permissions: ['contractor_panel']
        },
        {
            path       : "/drops-sur-demande/:id",
            component  : React.lazy(() => import("app/main/components/workOrder/sdWorkOrder/ShowWorkOrder/SDWorkOrder")),
            permissions: ['display_SD_drops']
        },
        {
            path       : "/drops-sur-demande",
            component  : React.lazy(() => import("app/main/components/workOrder/sdWorkOrder/ShowWorkOrders/SDWorkOrders")),
            permissions: ['display_SD_drops']
        },
        {
            path       : "/drops-sur-demande-a-valider",
            component  : React.lazy(() => import("app/main/components/workOrder/sdWorkOrder/ShowWorkOrders/WaitingWorkOrders")),
            permissions: ['display_SD_drops']
        },
        {
            path       : "/drops-sur-demande-en-preparation",
            component  : React.lazy(() => import("app/main/components/workOrder/sdWorkOrder/ShowWorkOrders/PrepareSuppliesWorkOrders")),
            permissions: ['display_SD_drops']
        },
        {
            path       : "/drops-sur-demande-a-attribuer",
            component  : React.lazy(() => import("app/main/components/workOrder/sdWorkOrder/ShowWorkOrders/ToAttributeTaskWorkOrders")),
            permissions: ['display_SD_drops']
        },
    ],
};
