import React from "react";

export const TelevisionServiceConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes  : [
        //televisionService
        {
            path       : "/television/stats",
            component  : React.lazy(() => import("app/main/components/services/televisionService/televisionStats/showTelevisionStats/TelevisionStats")),
            permissions: ["manage_television_service"],
        },

        //apmax
        {
            path       : "/apmax/difference",
            component  : React.lazy(() => import("app/main/components/services/televisionService/apMax/apMaxDifference/ApmaxDifference")),
            permissions: ["manage_apmax"],
        },
        {
            path       : "/apmax/clients-brise",
            component  : React.lazy(() => import("app/main/components/services/televisionService/apMax/apMaxBrokenCustomer/BrokenCustomers")),
            permissions: ["manage_apmax"],
        },
        {
            path       : "/apmax",
            component  : React.lazy(() => import("app/main/components/services/televisionService/apMax/apMax/Apmax")),
            permissions: ["manage_apmax"],
        },

        //televisionBase
        {
            path       : "/bases/nouvelle-base",
            component  : React.lazy(() => import("app/main/components/services/televisionService/base/CreateBase/CreateBase")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/bases/modifier/:id",
            component  : React.lazy(() => import("app/main/components/services/televisionService/base/updateBase/UpdateBase")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/bases",
            component  : React.lazy(() => import("app/main/components/services/televisionService/base/showBases/Bases")),
            permissions: ["manage_television_service"],
        },

        //BrokenChannelLock
        {
            path       : "/barrure-brise",
            component  : React.lazy(() => import("app/main/components/services/televisionService/BrokenChannelLock/BrokenChannelLocks")),
            permissions: ["manage_broken_channel_lock"],
        },

        //channel
        {
            path       : "/chaines/nouvelle-chaines",
            component  : React.lazy(() => import("app/main/components/services/televisionService/channel/createChannel/CreateChannel")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/chaines/modifier/:id",
            component  : React.lazy(() => import("app/main/components/services/televisionService/channel/updateChannel/UpdateChannel")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/chaines",
            component  : React.lazy(() => import("app/main/components/services/televisionService/channel/showChannels/Channels")),
            permissions: ["manage_television_service"],
        },

        //channelPackage
        {
            path       : "/groupe-chaines/creer",
            component  : React.lazy(() => import("app/main/components/services/televisionService/channelPackage/CreateChannelPackage/CreateChannelPackage")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/groupe-chaines/modifier/:id",
            component  : React.lazy(() => import("app/main/components/services/televisionService/channelPackage/updateChannelPackage/UpdateChannelPackage")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/groupe-chaines",
            component  : React.lazy(() => import("app/main/components/services/televisionService/channelPackage/ShowChannelPackages/ChannelPackages")),
            permissions: ["manage_television_service"],
        },

        //commercialPackage
        {
            path       : "/groupe-commercials/creer",
            component  : React.lazy(() => import("app/main/components/services/televisionService/commercialPackage/CreateCommercialPackage/CreateCommercialPackage")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/groupe-commercials/modifier/:id",
            component  : React.lazy(() => import("app/main/components/services/televisionService/commercialPackage/updateCommercialPackage/UpdateCommercialPackage")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/groupe-commercials",
            component  : React.lazy(() => import("app/main/components/services/televisionService/commercialPackage/ShowCommercialPackages/CommercialPackagesList")),
            permissions: ["manage_television_service"],
        },

        //megaChannelPackage
        {
            path       : "/mega-groupe-chaines/creer",
            component  : React.lazy(() => import("app/main/components/services/televisionService/megaChannelPackage/createChannelPackage/CreateMegaChannelPackage")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/mega-groupe-chaines/modifier/:id",
            component  : React.lazy(() => import("app/main/components/services/televisionService/megaChannelPackage/updateMegaChannelPackage/UpdateMegaChannelPackage")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/mega-groupe-chaines",
            component  : React.lazy(() => import("app/main/components/services/televisionService/megaChannelPackage/showMegaChannelPackages/MegaChannelPackages")),
            permissions: ["manage_television_service"],
        },

        //pickAndPackOption
        {
            path       : "/option-sur-mesures/creer",
            component  : React.lazy(() => import("app/main/components/services/televisionService/pickAndPackOption/createPickAndPackOption/CreatePickAndPackOption")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/option-sur-mesures/modifier/:id",
            component  : React.lazy(() => import("app/main/components/services/televisionService/pickAndPackOption/updatePickAndPackOption/UpdatePickAndPackOption")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/option-sur-mesures",
            component  : React.lazy(() => import("app/main/components/services/televisionService/pickAndPackOption/showPickAndPackOptions/PickAndPackOptions")),
            permissions: ["manage_television_service"],
        },

        //pickAndPackType
        {
            path       : "/sur-mesures/creer",
            component  : React.lazy(() => import("app/main/components/services/televisionService/pickAndPackType/createPickAndPackType/CreatePickAndPackTypes")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/sur-mesures/modifier/:id",
            component  : React.lazy(() => import("app/main/components/services/televisionService/pickAndPackType/updatePickAndPakcType/UpdatePickAndPackType")),
            permissions: ["manage_television_service"],
        },
        {
            path       : "/sur-mesures",
            component  : React.lazy(() => import("app/main/components/services/televisionService/pickAndPackType/showPickAndPackTypes/PickAndPackTypes")),
            permissions: ["manage_television_service"],
        },
    ],
};