export default function translateSuccessMessage(successMessage) {
    const translatedMessages = {
        //auth
        password_recovered_successfully: "Mot de passe changé avec succès",
        admin_creation_success         : "Administrateur créé avec succès",
        admin_update_success           : "Administrateur modifié avec succès",
        email_sent_successfully        : "Courriel envoyé avec succès",
        role_creation_success          : "Rôle créé avec succès",
        role_edit_success              : "Rôle modifié avec succès",
        role_suppression_success       : "Rôle supprimé avec succès",

        //common
        feedback_creation_success   : "Suggestion créée avec succès",
        feedback_suppression_success: "Suggestion supprimée avec succès",
        comment_update_success      : "Commentaire modifié avec succès",
        comment_creation_success    : "Commentaire créé avec succès",
        note_creation_success       : "Note créée avec succès",
        note_update_success         : "Note modifiée avec succès",

        //customer
        mass_mail_success                 : "Multiple emails envoyés avec succès",
        customer_estimate_creation_success: "Estimation créée avec succès",
        customer_estimate_update_success  : "Estimation du client modifiée avec succès",

        //finance
        fee_creation_success            : "Frais créé avec succès",
        fee_update_success              : "Frais modifié avec succès",
        fee_suppression_success         : "Frais supprimé avec succès",
        fee_sold_successfully           : "Frais vendu avec succès",
        fee_added_successfully          : "Frais ajouté avec succès",
        fee_removed_successfully        : "Frais retiré avec succès",
        in_cart_item_creation_success   : "Article du panier créé avec succès",
        in_cart_item_suppression_success: "Article du panier supprimé avec succès",
        in_cart_item_update_success     : "Article du panier modifié avec succès",

        //inventory
        cell_creation_success                       : "Cellule créée avec succès",
        cell_update_success                         : "Cellule modifiée avec succès",
        cell_suppression_success                    : "Cellule supprimée avec succès",
        atombo2_customer_creation_success           : "Client créé avec succès",
        delivery_creation_success                   : "Livraison créée avec succès",
        delivery_suppression_success                : "Livraison supprimée avec succès",
        delivery_update_success                     : "Livraison modifiée avec succès",
        node_creation_success                       : "Node créée avec succès",
        node_update_success                         : "Node modifiée avec succès",
        node_suppression_success                    : "Node supprimée avec succès",
        stock_creation_success                      : "Équipement créé avec succès",
        stock_update_success                        : "Équipement modifié avec succès",
        Stock_movement_success                      : "Équipement déplacé avec succès",
        stock_sold_successfully                     : "Équipement vendu avec succès",
        Stock_takenback_successfully                : "Équipement repris avec succès",
        supply_bundle_creation_success              : "paquet de matériaux créé avec succès",
        supply_consumption_success                  : "Matériaux consommés avec succès",
        supply_added_to_supply_bundle_successfully  : "Matériau ajouté au paquet de matériaux",
        Supply_movement_success                     : "Matériaux déplacé avec succès",
        supply_bundle_corrected_successfully        : "Quantité du paquet de matériaux corrigé avec succès",
        supply_creation_success                     : "Matériel créé avec succès",
        supply_update_success                       : "Matériel modifié avec succès",
        supply_suppression_success                  : "Matériel supprimé avec succès",
        supply_movement_success                     : "Matériel déplacé avec succès",
        movement_success                            : "Déplacement réussi",
        supply_emplacement_creation_success         : "Emplacement créé avec succès",
        supply_emplacement_update_success           : "Emplacement modifié avec succès",
        supply_emplacement_suppression_success      : "Emplacement supprimé avec succès",
        supply_minimum_creation_success             : "Minimum créé avec succès",
        supply_minimum_suppression_success          : "Minimum supprimé avec succès",
        supply_minimum_update_success               : "Minimum modifié avec succès",
        supply_source_creation_success              : "Fournisseur créé avec succès",
        supply_source_update_success                : "Fournisseur modifié avec succès",
        supply_source_suppression_success           : "Fournisseur supprimé avec succès",
        truck_creation_success                      : "Camion créé avec succès",
        truck_suppression_success                   : "Camion supprimé avec succès",
        truck_update_success                        : "Camion modifié avec succès",
        unit_creation_success                       : "Unité créée avec succès",
        unit_update_success                         : "Unité modifiée avec succès",
        unit_suppression_success                    : "Unité supprimée avec succès",
        warehouse_creation_success                  : "Entrepôt créé avec succès",
        warehouse_update_success                    : "Entrepôt modifié avec succès",
        warehouse_suppression_success               : "Entrepôt supprimé avec succès",
        expedibox_reservation_cancelled_successfully: "Réservation annulée avec succès",
        Stock_sold                                  : "Équipement vendu avec succès",

        //workOrder
        customer_wants_install_creation_success       : "Demande d'installation créée avec succès",
        customer_wants_install_suppression_success    : "Demande d'installation supprimée avec succès",
        city_contractor_creation_success              : "Assignation contracteur-ville créée avec succès",
        city_contractor_update_success                : "Assignation contracteur-ville modifié avec succès",
        city_contractor_suppression_success           : "Assignation contracteur-ville supprimé avec succès",
        contractor_creation_success                   : "Contracteur créé avec succès",
        contractor_update_success                     : "Contracteur modifié avec succès",
        contractor_suppression_success                : "Contracteur supprimé avec succès",
        repair_created_successfully                   : "Réparation créée avec succès",
        affected_customers_added_successfully         : "Clients affectés ajoutés avec succès",
        customer_added_successfully                   : "Client ajouté avec succès",
        customer_removed_successfully                 : "Client retiré avec succès",
        repair_approved_successfully                  : "Réparation approuvée avec succès",
        repair_completed_successfully                 : "Réparation complétée avec succès",
        repair_infos_updated_successfully             : "Informations de la réparation modifiées avec succès",
        repair_cancelled_successfully                 : "Réparation annulée avec succès",
        hold_repair_success                           : "Réparation mise en attente avec succès",
        in_progress_repair_success                    : "Réparation mise en progrès avec succès",
        admins_repairs_set_successfully               : "Administrateurs des réparations modifiés avec succès",
        repair_contractor_updated_successfully        : "Contracteur de la réparation modifié avec succès",
        sd_work_order_updated_successfully            : "Drop sur demande modifiée avec succès",
        sd_work_order_creation_success                : "Drop sur demande créée avec succès",
        sd_work_order_approved_successfully           : "Drop sur demande approuvée avec succès",
        sd_work_order_cancelled_successfully          : "Drop sur demande annulée avec succès",
        sd_work_order_completed_successfully          : "Drop sur demande complétée avec succès",
        sd_work_order_infos_updated_successfully      : "Informations de la drop sur demande modifiés avec succès",
        sd_work_order_await_completion_successful     : "Drop sur demande mise en attente de complétion avec succès",
        hold_sd_work_order_success                    : "Drop sur demande mise en attente avec succès",
        in_progress_sd_work_order_success             : "Drop sur demande mise en cours avec succès",
        admins_sd_work_orders_set_successfully        : "Administrateurs des drop sur demandes modifiés avec succès",
        fiber_and_box_number_added_successfully       : "Informations de la drop sur demande modifiées avec succès",
        sd_work_order_attachment_prepared_successfully: "Pièce jointe attachée avec succès",
        distance_validated_successfully               : "Informations de la drop sur demande modifiées avec succès",
        sd_work_order_to_attributed_task_successful   : "Drop sur demande modifiée avec succès",

        //service
        channel_creation_success                             : "Chaine créée avec succès",
        channel_update_success                               : "Chaine modifiée avec succès",
        channel_suppression_success                          : "Chaine supprimée avec succès",
        channel_feed_addition_success                        : "Ajout de poste avec succès",
        access_point_creation_success                        : "Point d'accès créé avec succès",
        access_point_update_success                          : "Point d'accès modifié avec succès",
        access_point_suppression_success                     : "Point d'accès supprimé avec succès",
        accessPointRouter_update_success                     : "routeur de point d'accès modifié avec succès",
        accessPointRouter_suppression_success                : "routeur de point d'accès supprimé avec succès",
        antenna_update_success                               : "Antenne modifiée avec succès",
        antenna_suppression_success                          : "Antenne supprimée avec succès",
        base_creation_success                                : "Base créée avec succès",
        base_update_success                                  : "Base modifiée avec succès",
        broken_channel_lock_suppression_success              : "Base supprimée avec succès",
        calix_customer_created_successfully                  : "Client calix créé avec succès",
        calix_customer_deleted_successfully                  : "Client calix supprimé avec succès",
        customer_calix_device_added_successfully             : "Équipement Calix ajouté au client avec succès",
        customer_calix_device_removed_successfully           : "Équipement Calix retiré du client avec succès",
        customer_calix_device_replaced_successfully          : "Équipement Calix remplacé avec succès",
        channel_feed_update_success                          : "Assignation chaine-poste modifiée avec succès",
        channel_feed_suppression_success                     : "Assignation chaine-poste supprimée avec succès",
        lock_broken_successfully                             : "Barrure brisée avec succès",
        channel_package_creation_success                     : "Groupe de chaines créé avec succès",
        channel_package_update_success                       : "Groupe de chaines modifié avec succès",
        commercial_package_creation_success                  : "Groupe commercial de chaines créé avec succès",
        commercial_package_update_success                    : "Groupe commercial de chaines modifié avec succès",
        gateway_creation_success                             : "Gateway créé avec succès",
        gateway_update_success                               : "Gateway modifié avec succès",
        gateway_set_to_static_ip_successfully                : "Gateway mit en IP statique avec succès",
        internet_plan_category_creation_success              : "Categorie de forfait interenet créée avec succès",
        internet_plan_category_update_success                : "Categorie de forfait interenet modifiée avec succès",
        internet_plan_creation_success                       : "Forfait internet créé avec succès",
        internet_plan_update_success                         : "Forfait internet modifié avec succès",
        internet_plan_exception_suppression_success          : "Forfait internet supprimé avec succès",
        internet_plan_package_creation_success               : "Groupe de forfait internet créé avec succès",
        internet_plan_package_update_success                 : "Groupe de forfait internet modifié avec succès",
        internet_plan_package_set_to_default_fiber_successful: "Groupe de forfait défini comme groupe fibre par défaut",
        available_static_ip_creation_success                 : "IP statique disponible créé avec succès",
        available_static_ip_suppression_success              : "IP statique disponible supprimé avec succès",
        internet_service_creation_success                    : "Service internet créé avec succès",
        internet_plan_added_successfully                     : "Forfait internet ajouté avec succès",
        internet_plan_changed_successfully                   : "Forfait internet modifié avec succès",
        internet_plan_change_planned_successfully            : "Prévision de changement du forfait internet planifié avec succès",
        next_plan_set_successfully                           : "Prochain forfait planifié avec succès",
        internet_plan_upgraded_successfully                  : "Forfait internet améliorer avec succès",
        internet_plan_upgrade_changed_successfully           : "Forfait internet de l'amélioration temporaire modifier avec succès",
        internet_plan_removed_successfully                   : "Forfait internet retiré avec succès",
        service_suspended_successfully                       : "Service suspendu avec succès",
        service_interrupted_successfully                     : "Service interrompu avec succès",
        service_uninterupted_successfully                    : "Service activé avec succès",
        service_stopped_successfully                         : "Service arrêté avec succès",
        alerts_set_successfully                              : "Alerte créée avec succès",
        exception_added_successfully                         : "Exception ajouté avec succès",
        static_ip_option_toggled_successfully                : "Option IP statique activé avec succès",
        mega_channel_package_creation_success                : "Mega groupe de chaine créé avec succès",
        mega_channel_package_update_success                  : "Mega groupe de chaine modifié avec succès",
        network_authorisation_creation_success               : "Authorisation réseau créée avec succès",
        network_authorisation_update_success                 : "Authorisation réseau modifiée avec succès",
        network_authorisation_suppression_success            : "Authorisation réseau supprimée avec succès",
        service_reprovisionning_started_successfully         : "Réapprovisionnement débuté avec succès",
        optical_line_termination_creation_success            : "Tête de ligne créée avec succès",
        optical_line_termination_update_success              : "Tête de ligne modifiée avec succès",
        optical_line_termination_suppression_success         : "Tête de ligne supprimée avec succès",
        pick_and_pack_option_creation_success                : "Option de sur mesure créé avec succès",
        pick_and_pack_option_update_success                  : "Option de sur mesure modifié avec succès",
        pick_and_pack_option_suppression_success             : "Option de sur mesure supprimé avec succès",
        pick_and_pack_type_creation_success                  : "Type de sur mesure créé avec succès",
        pick_and_pack_type_update_success                    : "Type de sur mesure modifié avec succès",
        radio_update_success                                 : "Radio modifiée avec succès",
        radio_suppression_success                            : "Radio supprimée avec succès",
        radio_defined_successfully                           : "Radio défini avec succès",
        receiver_replacement_success                         : "Récepteur remplacé avec succès",
        receiver_suppression_success                         : "Récepteur supprimé avec succès",
        receiver_updated_successfully                        : "Récepteur modifié avec succès",
        secondary_ont_created_successfully                   : "ONT secondaire créé avec succès",
        secondary_ont_updated_successfully                   : "ONT secondaire modifié avec succès",
        secondary_ont_deleted_successfully                   : "ONT secondaire supprimé avec succès",
        sellable_internet_supplies_set_successfully          : "Matériel défini comme matériel internet avec succès",
        stb_reboot_success                                   : "STB redémarré avec succès",
        to_ignore_numbers_imported_successfully              : "Les numéros à ignorés ont été importés avec succès",
        send_number_test_success                             : "Numéro de test ajouté avec succès",
        pbx_creation_success                                 : "PBX créé avec succès",
        pbx_update_success                                   : "PBX modifié avec succès",
        phone_call_rate_creation_success                     : "Taux appel téléphonique créé avec succès",
        phone_line_creation_success                          : "Ligne téléphonique créé avec succès",
        phone_plan_creation_success                          : "Forfait téléphonique créé avec succès",
        phone_plan_update_success                            : "Forfait téléphonique modifié avec succès",
        city_link_success                                    : "Liaison des villes avec succès",
        telephone_service_suspension_successful              : "Téléphone service suspendue avec succès",
        telephone_service_interruption_successful            : "Téléphone service interrompue avec succès",
        phone_line_restored_successfully                     : "Ligne téléphonique restauré avec succès",
        television_service_creation_success                  : "Service television créé avec succès",
        region_changed_successfully                          : "Région changé avec succès",
        channel_selection_success                            : "Choix des chaines avec succès",
        stb_added_successfully                               : "Décodeur ajouté avec succès",
        set_top_box_updated_successfully                     : "Décodeur modifié avec succès",
        set_top_box_removal_success                          : "Décodeur retiré avec succès",
        delete_number_test_success                           : "Numéro de test supprimé avec succès",
        password_reset_successfully                          : "Mot de passe réinitialisé avec succès",
        vm_language_updated_successfully                     : "Language de la boite vocale modifié avec succès",
        ont_replacement_success                              : "ONT remplacé avec succès",
        ont_edited_successfully                              : "ONT modifié avec succès",
        ont_internet_service_edited_successfully             : "Service internet modifié dans l'ONT avec succès",
        ont_telephone_service_edited_successfully            : "Service téléphone modifié dans l'ONT avec succès",
        ont_television_service_edited_successfully           : "Service télévision modifié dans l'ONT avec succès",
        ont_service_removed_successfully                     : "service retiré de l'ONT avec succès",
        ont_provisioned_successfully                         : "ONT provisionné avec succès",
        ont_reboot_success                                   : "ONT redémarré avec succès",
        service_suspension_success                           : "Service suspendu avec succès",
        service_resume_success                               : "Service repartie avec succès",
        receiver_creation_success                            : "Récepteur créé avec succès",
        receiver_update_success                              : "Récepteur mis à jour avec succès",
        atombo2_customers_creation_success                   : "Client créé avec succès",
        receiver_olt_updated_successfully                    : "OLT mis à jour avec succès",
        configured_radius_refreshed_successfully             : "Radius configuré rafraîchi avec succès",

        //downable
        cell_down_successful                         : "Cellule marquée problématique avec succès",
        node_down_successful                         : "Node marqué problématique avec succès",
        gateway_down_successful                      : "Gateway marqué problématique avec succès",
        opticalLineTermination_down_successful       : "Tête de ligne marquée problématique avec succès",
        accessPoint_down_successful                  : "Point d'accès marqué problématique avec succès",
        cell_remove_down_successful                  : "Cellule marquée non problématique avec succès",
        node_remove_down_successful                  : "Node marqué non problématique avec succès",
        gateway_remove_down_successful               : "Gateway marqué non problématique avec succès",
        opticalLineTermination_remove_down_successful: "Tête de ligne marquée non problématique avec succès",
        accessPoint_remove_down_successful           : "Point d'accès marqué non problématique avec succès",
        networkRing_remove_down_successful           : "Anneau marqué non problématique avec succès",
        networkRing_down_successful                  : "Anneau marqué problématique avec succès",

        //Network rings
        network_ring_created_successfully: "Anneau réseau ajouté avec succès",
        network_ring_updated_successfully: "Anneau réseau modifié avec succès",
        network_ring_deleted_successfully: "Anneau réseau supprimé avec succès",
    };

    return translatedMessages[successMessage] || successMessage;
}