import React, { Component } from "react";
import { matchRoutes }      from "react-router-config";
import { withRouter }       from "react-router-dom";
import { connect }          from "react-redux";
import AppContext           from "app/AppContext";

class FuseAuthorization extends Component {

    constructor(props, context) {
        super(props);
        const {routes} = context;
        this.state     = {
            accessGranted: true,
            routes,
        };
    }

    componentDidMount() {
        if (! this.state.accessGranted) {
            this.redirectRoute();
        }
    }

    componentDidUpdate() {
        if (! this.state.accessGranted) {
            this.redirectRoute();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {location, user} = props;
        const {pathname}       = location;
        const matched          = matchRoutes(state.routes, pathname)[0];
        let accessGranted      = true;
        if (user.data && user.data.permissions) {
            let neededPermissions = (matched && matched.route.permissions && matched.route.permissions.length > 0) ? matched.route.permissions : [];
            neededPermissions.forEach(function(neededPermission) {
                if (! user.data.permissions.find(function(permission) {
                    return permission.code === neededPermission;
                })) {
                    accessGranted = false;
                }
            });
        } else if (matched && matched.route.permissions) {
            accessGranted = false;
        } else if (! user.data && ! matched.route.authFree) {
            accessGranted = false;
        }

        return {
            accessGranted,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.accessGranted !== this.state.accessGranted;
    }

    redirectRoute() {
        const {location, userRole, history} = this.props;
        const {pathname, state}             = location;

        /*
         User is guest
         Redirect to Login Page
         */
        if (! userRole || userRole.length === 0) {
            history.push({
                pathname: "/login",
                state   : {redirectUrl: pathname},
            });
        }
        /*
         User is member
         User must be on unAuthorized page or just logged in
         Redirect to dashboard or redirectUrl
         */
        else {
            const redirectUrl = state && state.redirectUrl ? state.redirectUrl : "/error-403";
            history.push({
                pathname: redirectUrl,
            });
        }
    }

    render() {
        // console.info('Fuse Authorization rendered', accessGranted);
        return this.state.accessGranted ? <React.Fragment>{this.props.children}</React.Fragment> : null;
    }
}

function mapStateToProps({auth}) {
    return {user: auth.user};
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(FuseAuthorization));
