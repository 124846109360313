import red         from "@material-ui/core/colors/red";

const themesConfig = {
    fuse: {
        palette: {
            type      : "light",
            primary   : {
                light       : "#efefe3",
                main        : "#08415C",
                dark        : "#1d2e41",
                contrastText: "#efefe3",
                100         : "#C5C6CB",
                200         : "#75767B",
            },
            secondary : {
                dark        : "#ffd06b",
                main        : "#fdba29",
                light       : "#ffca7b",
                contrastText: "#242325",
            },
            background: {
                default  : "#242325",
                secondary: "#f7faff",
                paper    : "#FCFDFF",
            },
            error     : red,
        },
        status : {
            danger: "orange",
        },
    },
};


export default themesConfig;
