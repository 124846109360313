import { AT_NOTIFICATION } from "app/main/store/common/actions/actions-types";

export function updateNotification(notifications) {
    return function(dispatch) {
        dispatch({
            type   : AT_NOTIFICATION.READ_ALL_NOTIFICATIONS,
            payload: notifications,
        });
    };
}

export function toggleNotificationPanel() {
    return function(dispatch) {
        dispatch({
            type: AT_NOTIFICATION.TOGGLE_NOTIFICATION_PANEL,
        });
    };
}
