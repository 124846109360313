export function getDateDiff(date) {
    const notificationDateDiff = new Date() - new Date(date);
    const daysSinceNotification = notificationDateDiff / (60 * 60 * 24 * 1000);
    let dateDiff = new Date(date).toLocaleDateString("default", {
        weekday: "long",
        year   : "numeric",
        month  : "long",
        day    : "numeric",
    });
    if (daysSinceNotification < 5 && daysSinceNotification >= 1) {
        dateDiff = Math.round(daysSinceNotification) + " jours";
    }
    if (daysSinceNotification < 1) {
        dateDiff = Math.round(daysSinceNotification * 24) + " heures";
    }
    if (daysSinceNotification * 24 < 1) {
        dateDiff = Math.ceil(daysSinceNotification * 24 * 60 / 5) * 5 + " minutes";

    }
    return dateDiff;
}

export function newDateWithTimeZone(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getHours(), date.getMinutes(), date.getSeconds());
}

export function getStorageableUrl(storageable) {
    switch (storageable) {
        case "warehouse":
            return "entrepots";
        case "truck":
            return "camions";
        case "delivery":
            return "livraisons";
        case "cell":
            return "cellules";
        case "node":
            return "nodes";
        case "atombo2Customer":
            return "clients";
        default:
            return "";
    }
}

export function formatPrice(value) {
    return parseFloat(value).toFixed(2).replace(".", ",");
}

export function getBoolean(string) {
    return (string) ? 1 : 0;
}

export function parseBool(value) {
    return value === "1" || value === "true" || value === 1;
}

export function formatBoolForShow(bool) {
    return Boolean(bool) ? "Oui" : "Non";
}

export function transformObjectToArray(key, value) {
    let object2;
    if (typeof value === "object" && value !== null) {
        object2 = {};
        for (const [key2, value2] of Object.entries(value)) {
            for (const [key3, value3] of Object.entries(transformObjectToArray(key + "[" + key2 + "]", value2))) {
                object2[key3] = value3;
            }
        }
    } else {
        object2 = {};
        object2[key] = value;
    }
    return object2;
}

export function formatPhoneNumber(phoneNumber) {
    return phoneNumber.replace(/\D/g, "");
}

export function formatPhoneNumberForShow(phoneNumber) {
    let length = phoneNumber.length;
    if (phoneNumber === 'Unavailable') {
        return 'Unavailable';
    }
    if (!length) {
        return "";
    }
    if (length > 10) {
        return `${phoneNumber.substring(0, length - 10)}-${phoneNumber.substring(length - 10, length - 7)}-${phoneNumber.substring(length - 7, length - 4)}-${phoneNumber.substring(length - 4, length)}`;
    }
    return `(${phoneNumber.substring(0, length - 7)}) ${phoneNumber.substring(length - 7, length - 4)}-${phoneNumber.substring(length - 4, length)}`;
}

export function formatDateForShow(date) {
    if (Boolean(date)) {
        const dateWithTimeZone = newDateWithTimeZone(new Date(date))
        return dateWithTimeZone.toLocaleDateString("default", {
            year  : "numeric",
            month : "long",
            day   : "numeric",
            hour  : "numeric",
            minute: "numeric",
        });
    }
    return "";

}

export function formatDateForShowWithoutHour(date) {
    if (Boolean(date)) {
        const dateWithTimeZone = newDateWithTimeZone(new Date(date))
        return dateWithTimeZone.toLocaleDateString("default", {
            year : "numeric",
            month: "long",
            day  : "numeric",
        });
    }
    return "";
}

export function convertTimeToHours({hours, minutes}) {
    return (parseInt(hours) + parseInt(minutes) / 60).toFixed(2);
}

export function formatDemandsFilters(filters) {
    let formattedFilters = [];

    if (filters.states.length !== 0) {
        formattedFilters = formattedFilters.concat(
            {
                name  : "inStates",
                states: filters.states.map((state) => state.name),
            },
        );
    }

    if (filters.types && filters.types.length !== 0) {
        formattedFilters = formattedFilters.concat(
            {
                name : "inTypes",
                types: filters.types.map((type) => type.name),
            },
        );
    }

    if (filters.contractors.length !== 0) {
        formattedFilters = formattedFilters.concat(
            {
                name           : "fromContractors",
                contractors_ids: filters.contractors.map((contractor) => contractor.name),
            },
        );
    }

    return formattedFilters;
}

export function formatFilterForDemands(filters) {
    let FilterObject = [];
    const stateFilters = filters.filter(filter => filter.type === "states").map(filter => filter.value);
    const typeFilters = filters.filter(filter => filter.type === "types").map(filter => filter.value);
    const cityFilters = filters.filter(filter => filter.type === "cities").map(filter => filter.value.id);
    const contractorsFilters = filters.filter(filter => filter.type === "contractors").map(filter => filter.value);
    const datesFilters = filters.filter(filter => filter.type === "dates").map(filter => filter.value.toISOString().substr(0, 10));
    const hasDistanceValidatedFilter = filters.filter(filter => filter.type === "hasDistanceValidated");
    const hasFiberValidatedFilter = filters.filter(filter => filter.type === "hasFiberValidated");

    if (stateFilters.length !== 0) {
        FilterObject = FilterObject.concat([{
            "name"  : "inStates",
            "states": stateFilters,
        }]);
    }

    if (cityFilters.length !== 0) {
        FilterObject = FilterObject.concat([{
            "name"      : "fromCities",
            "cities_ids": cityFilters,
        }]);
    }

    if (typeFilters.length !== 0) {
        FilterObject = FilterObject.concat([{
            "name" : "inTypes",
            "types": typeFilters,
        }]);
    }

    if (contractorsFilters.length !== 0) {
        FilterObject = FilterObject.concat([{
            "name"           : "fromContractors",
            "contractors_ids": contractorsFilters,
        }]);
    }

    if (datesFilters.length !== 0) {
        FilterObject = FilterObject.concat([{
            "name" : "inDates",
            "dates": datesFilters,
        }]);
    }

    if (hasDistanceValidatedFilter.length !== 0) {
        FilterObject = FilterObject.concat([{"name": "hasDistanceValidated"}]);
    }

    if (hasFiberValidatedFilter.length !== 0) {
        FilterObject = FilterObject.concat([{"name": "hasFiberValidated"}]);
    }

    return transformObjectToArray("filters", FilterObject);
}

export function formatFiltersForCustomers(filters) {
    const filteredFilters = (filters, attribute) => {
        return filters.filter(f => f.type === attribute).map(f => f.value);
    };

    const isInService = (filters) => {
        return filteredFilters(filters, "notInService").length === 0
    };

    let filtersObject = [];
    const citiesFilter = filteredFilters(filters, "city_ids");
    const suppliesFilter = filteredFilters(filters, "supply_ids");
    const accessPointFilters = filteredFilters(filters, "access_point_ids");
    const oltFilters = filteredFilters(filters, "olt_ids");

    const internetFilters = filters.filter(f => f.service === "internet");
    const televisionFilters = filters.filter(f => f.service === "television");
    const telephoneFilters = filters.filter(f => f.service === "telephone");

    if (citiesFilter.length !== 0) {
        filtersObject = filtersObject.concat([{
            name    : "fromCities",
            city_ids: citiesFilter,
        }]);
    }

    if (suppliesFilter.length !== 0) {
        filtersObject = filtersObject.concat([{
            name      : "inSupplies",
            supply_ids: suppliesFilter,
        }]);
    }

    if (accessPointFilters.length !== 0 || oltFilters.length !== 0) {
        const formattedTransmittersFilter = {name: "withTransmitters"};

        if (accessPointFilters.length !== 0) {
            formattedTransmittersFilter['access_point_ids'] = accessPointFilters;
        }

        if (oltFilters.length !== 0) {
            formattedTransmittersFilter['optical_line_termination_ids'] = oltFilters;
        }

        filtersObject = filtersObject.concat([formattedTransmittersFilter]);
    }

    if (internetFilters.length !== 0) {
        const formattedInternetFilters = {
            name   : isInService(internetFilters) ? "inService" : "notInService",
            service: "internet",
        };

        if (isInService(internetFilters)) {
            [
                {filter: filteredFilters(internetFilters, "states"), parameter: "states"},
                {filter: filteredFilters(internetFilters, "internet_plan_ids"), parameter: "internet_plan_ids"},
            ].forEach(f => {
                if (f.filter.length !== 0) {
                    formattedInternetFilters[f.parameter] = f.filter;
                }
            });
        }

        filtersObject = filtersObject.concat([formattedInternetFilters]);
    }

    if (telephoneFilters.length !== 0) {
        const formattedTelephoneFilters = {
            name   : isInService(telephoneFilters) ? "inService" : "notInService",
            service: "telephone",
        };

        if (isInService(telephoneFilters)) {
            [
                {filter: filteredFilters(telephoneFilters, "states"), parameter: "states"},
                {filter: filteredFilters(telephoneFilters, "phone_plan_ids"), parameter: "phone_plan_ids"},
            ].forEach(f => {
                if (f.filter.length !== 0) {
                    formattedTelephoneFilters[f.parameter] = f.filter;
                }
            });

            const lineCountFilter = telephoneFilters.find(f => f.type === "phone_line_count");

            if (Boolean(lineCountFilter)) {
                formattedTelephoneFilters['phone_line_count'] = lineCountFilter.value;
            }
        }

        filtersObject = filtersObject.concat([formattedTelephoneFilters]);
    }

    if (televisionFilters.length !== 0) {
        const formattedTelevisionFilters = {
            name   : isInService(televisionFilters) ? "inService" : "notInService",
            service: "television",
        };

        if (isInService(televisionFilters)) {
            [
                {filter: filteredFilters(televisionFilters, "states"), parameter: "states"},
                {filter: filteredFilters(televisionFilters, "channel_ids"), parameter: "channel_ids"},
                {filter: filteredFilters(televisionFilters, "package_ids"), parameter: "package_ids"},
                {filter: filteredFilters(televisionFilters, "pick_and_pack_option_ids"), parameter: "pick_and_pack_option_ids"},
                {filter: filteredFilters(televisionFilters, "pick_and_pack_type_ids"), parameter: "pick_and_pack_type_ids"},
                {filter: filteredFilters(televisionFilters, "pick_and_pack_channel_ids"), parameter: "pick_and_pack_channel_ids"},
                {filter: filteredFilters(televisionFilters, "mega_package_ids"), parameter: "mega_package_ids"},
                {filter: filteredFilters(televisionFilters, "base_ids"), parameter: "base_ids"},
            ].forEach(f => {
                if (f.filter.length !== 0) {
                    formattedTelevisionFilters[f.parameter] = f.filter;
                }
            });
        }

        filtersObject = filtersObject.concat([formattedTelevisionFilters]);
    }

    return transformObjectToArray("filters", filtersObject);
}

export function getFirstOfThisMonth() {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth());
}

export function formatTimeSeconds(seconds) {
    return new Date(seconds * 1000).toISOString().substring(11, 19);
}

export function formatAddress(address) {
    return `${address.unit_no !== "" ? `${address.unit_no}-` : ""}${address.civic_no} ${address.street_name}, ${address.city}, ${address.postal_code}`;
}
