import React                  from "react";
import { IconButton }   from "@material-ui/core";
import * as quickPanelActions from "./store/actions";
import { useDispatch }        from "react-redux";
import { FontAwesomeIcon }    from "@fortawesome/react-fontawesome";
import { faCog }              from "@fortawesome/free-solid-svg-icons/faCog";

function QuickPanelToggleButton(props) {
    const dispatch = useDispatch();

    return (
        <IconButton className="w-64 h-64" onClick={ev => dispatch(quickPanelActions.toggleQuickPanel())}>
            {props.children}
        </IconButton>
    );
}

QuickPanelToggleButton.defaultProps = {
    children: <FontAwesomeIcon icon={faCog}/>,
};

export default QuickPanelToggleButton;
