import React             from "react";
import StockButton       from "app/main/genericComponents/buttons/modelButtons/models/StockButton";
import StorageableButton from "app/main/genericComponents/buttons/modelButtons/models/StorageableButton";
import Grid              from "@material-ui/core/Grid";
import Typography        from "@material-ui/core/Typography";

export function antenna_in_access_point_in_use(errors) {
    return <div className={"max-w-sm"}>
        <Typography>{"L'antenne "}</Typography>
        <StockButton className={"mx-4"} stock={errors.stock}/>
        <Typography>{" est en utilisation."}</Typography>
        <Grid container>
            <Grid item xs={12}>
                {"Dans le point accès : " + errors.access_point.name}
            </Grid>
            <Grid item xs={12}>
                {"Dans la cellule : "}
                <StorageableButton
                    storageable={{
                        storageable_id  : errors.access_point.cell.id,
                        storageable_name: errors.access_point.cell.name,
                        storageable_type: "cell",
                    }}/>
            </Grid>
        </Grid>
    </div>;
}
