import { AT_NOTIFICATION } from "app/main/store/common/actions/actions-types";

const initialState = {
    errors       : [],
    notifications: [],
    panelIsOpen  : false,
};

export default function(state = initialState, action) {

    switch (action.type) {

        case AT_NOTIFICATION.READ_ALL_NOTIFICATIONS: {
            let newState = {
                ...state,
                notifications: action.payload,
            };
            return newState;
        }

        case AT_NOTIFICATION.TOGGLE_NOTIFICATION_PANEL: {
            let newState = {
                ...state,
                panelIsOpen: ! state.panelIsOpen,
            };
            return newState;
        }
        default : {
            return state;
        }
    }
}