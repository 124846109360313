import React                  from "react";
import { Typography }         from "@material-ui/core";
import { FontAwesomeIcon }    from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons";
import Paper                  from "@material-ui/core/Paper";
import { getDateDiff }        from "app/services/Utils";
import { faClock }            from "@fortawesome/pro-solid-svg-icons/faClock";
import { readNotifications } from "app/services/axios/Common/notification";
import PrimaryButton         from "app/main/genericComponents/buttons/rectangleButtons/PrimaryButton";

export default function NotificationBase(props) {

    function handleRedirect() {
        props.onRedirect();
        readNotifications([props.notification.id]);
    }

    const dateDiff = getDateDiff(props.notification.created_at);
    return (
        <Paper className={"p-16 my-16  " + (Boolean(props.notification.read_at) ? "bg-gray-300 opacity-50 " : "")}>
            <div className={"flex justify-between w-full items-center"}>
                {props.content}
                <PrimaryButton
                    className={"opacity-100"}
                    onClick={() => handleRedirect()}
                    buttontext={"Voir"}
                    endIcon={<FontAwesomeIcon icon={faArrowCircleRight}/>}
                    variant={"outlined"}
                    color={"primary"}
                />
            </div>
            <div className={"flex w-full items-center min-h-32"}>
                <FontAwesomeIcon icon={faClock} size={"lg"}/>
                <Typography className={"align-middle ml-8"}>
                    {" Il y a " + dateDiff + "."}
                </Typography>
            </div>
        </Paper>
    );
}


