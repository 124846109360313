import React, {Component}    from "react";
import Select, {components}  from "react-select";
import {Typography}          from "@material-ui/core";
import translateErrorMessage from "app/services/lang/translateErrorMessage";
import CircularProgress      from "@material-ui/core/CircularProgress";
import {connect}             from "react-redux";
import _                     from "@lodash";

const {Placeholder} = components;

const CustomValueContainer = ({children, ...props}) => {
    return (
        <components.ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child => {
                return child && child.type !== Placeholder ? child : null;
            })}
        </components.ValueContainer>
    );
};

function onChange(event, onChange) {
    if (!Boolean(event)) {
        event = {value: ""};
    }
    return onChange({value: event.value});
}

class DefaultSelectField extends Component {
    menu = (props) => {
        return (
            <>
                {this.props.loading && (
                    <components.Menu {...props}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress color={"secondary"} size={100}/>
                        </div>
                    </components.Menu>
                )}
                {!this.props.loading && (
                    <components.Menu {...props}>{props.children}</components.Menu>
                )}
            </>
        );
    };

    render() {
        return (
            <>
                <Select
                    {...this.props}
                    value={(this.props.items.find(item => _.isEqual(item.value, this.props.value)))
                           ? this.props.items.find(item => _.isEqual(item.value, this.props.value))
                           : null}
                    className={"basic-single " + this.props.className}
                    classNamePrefix="select"
                    name={this.props.name}
                    options={this.props.items}
                    onChange={(event) => onChange(event, this.props.onChange, this.props.name)}
                    components={{
                        ValueContainer: CustomValueContainer,
                        Menu          : this.menu,
                    }}
                    placeholder={this.props.label}
                    menuPortalTarget={document.body}
                    isDisabled={this.props.disabled}
                    onInputChange={(searchText) => this.props.handleSearch(searchText)}
                    styles={{
                        menuPortal    : base => ({...base, zIndex: 9999}),
                        control       : (base) => ({
                            ...base,
                            height     : 60,
                            minHeight  : 35,
                            marginTop  : 10,
                            borderColor: (this.props.errors) ? "red" : base.borderColor,
                            "&:hover"  : {
                                borderColor: (this.props.errors) ? "red" : base.borderColor,
                            },
                        }),
                        container     : (provided) => ({
                            ...provided,
                            marginTop: 15,
                            ...this.props.containerStyle,
                        }),
                        valueContainer: (provided) => ({
                            ...provided,
                            overflow: "visible",
                        }),
                        placeholder   : (provided, state) => ({
                            ...provided,
                            backgroundColor: "#FFFFFF",
                            padding        : 3,
                            position       : "absolute",
                            top            : state.hasValue || state.selectProps.inputValue ? -15 : "50%",
                            transition     : "top 0.1s, font-size 0.1s",
                            fontSize       : (state.hasValue || state.selectProps.inputValue) && 13,
                        }),
                    }}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: this.props.theme.mainThemeLight.palette.secondary.light,
                            primary  : this.props.theme.mainThemeLight.palette.secondary.main,
                        },
                    })}
                />
                {Boolean(this.props.errors) && (
                    <Typography className={"text-red"}>{translateErrorMessage(this.props.errors)}</Typography>
                )}
            </>
        );
    }
}

DefaultSelectField.defaultProps = {
    loading     : false,
    disabled    : false,
    handleSearch: () => {
    },
};

function mapStateToProps({fuse}) {
    return {theme: fuse.settings.themes};
}

export default connect(mapStateToProps)(DefaultSelectField);
