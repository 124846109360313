import React, { Component }  from "react";
import IfPanel               from "app/main/genericComponents/IfPanel";
import StorageableIcon       from "app/main/genericComponents/StorageableIcon";
import { getStorageableUrl } from "app/services/Utils";
import GenericModelButton    from "app/main/genericComponents/buttons/modelButtons/GenericModelButton";

class StorageableButton extends Component {

    gertStorageableId() {
        if (this.props.storageable.storageable_type === "atombo2Customer") {
            return this.props.storageable.storageable_name.substring(7);
        }
        return this.props.storageable.storageable_id;
    }

    render() {
        return (
            <IfPanel
                adminPanel={<GenericModelButton
                    url={"/" + getStorageableUrl(this.props.storageable.storageable_type) + "/" + this.gertStorageableId()}
                    buttontext={this.props.storageable.storageable_name}
                    startIcon={this.props.withstarticon ? (
                        <StorageableIcon storageableType={this.props.storageable.storageable_type}
                                         className={"opacity-75 ml-4"}/>) : ""}
                />}
                warehousePanel={this.props.storageable.storageable_name}
                truckPanel={this.props.storageable.storageable_name}
            />
        );
    }
}

StorageableButton.defaultProps = {
    withstarticon: 1,
};

export default (StorageableButton);
