import React             from "react";
import Typography        from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FabButton         from "app/main/genericComponents/buttons/IconButtons/FabButton";
import {faCopy}          from "@fortawesome/pro-solid-svg-icons/faCopy";

export function pickup_planned_successfully(response) {
    return <div>
        <Typography>La récupération à bien été planifiée.</Typography>
        <div className={"flex justify-between items-center"}>
            <Typography className={"mr-4"}>Le code de la case est : {response.pickup_code}</Typography>
            <FabButton
                onClick={() => navigator.clipboard.writeText(response.pickup_code)}
                buttontext={<FontAwesomeIcon icon={faCopy}/>}
            />
        </div>

    </div>;
}
