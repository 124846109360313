import store                   from "app/store";
import * as AppActions         from "app/store/actions";
import * as successMessages    from "app/main/genericComponents/successMessage/index";
import React                   from "react";
import translateSuccessMessage from "app/services/lang/translateSuccessMessage";

export function handleSuccessMessages(response) {
   if (Boolean(successMessages[response.message])) {
        store.dispatch(AppActions.showMessage({
            message         : successMessages[response.message](response),
            autoHideDuration: 10000,//ms
            anchorOrigin    : {vertical: "bottom", horizontal: "right"},
            variant         : "success",
        }));
    } else {
        store.dispatch(AppActions.showMessage({
            message         : translateSuccessMessage(response.message),
            autoHideDuration: 5000,//ms
            anchorOrigin    : {vertical: "bottom", horizontal: "right"},
            variant         : "success",
        }));
    }

}