import React             from "react";
import Typography        from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FabButton         from "app/main/genericComponents/buttons/IconButtons/FabButton";
import {faCopy}          from "@fortawesome/pro-solid-svg-icons/faCopy";

export function deposit_planned_successfully(response) {
    var doorRender = <></>
    if (Boolean(response.door_position)) {
        doorRender = <Typography className={"mr-4"}>La case est {response.door_position}</Typography>
    }

    return <div>
        <Typography>Le dépôt à bien été planifiée.</Typography>
        <div className={"flex justify-between items-center"}>
            <Typography className={"mr-4"}>Le code de la case est : {response.deposit_code}</Typography>
            {doorRender}
            <FabButton
                onClick={() => navigator.clipboard.writeText(response.deposit_code)}
                buttontext={<FontAwesomeIcon icon={faCopy}/>}
            />
        </div>

    </div>;
}
