function getErrorMessage(errorMessage, value1 = "", value2 = "") {
    const traduction = {
        "is_not_accepted"              : "n'est pas accepté",
        "is_not_valid_URL"             : "doit être un URL valide",
        "is_after"                     : "doit être après le " + value1,
        "is_after_or_equal"            : "doit être le " + value1 + " ou après",
        "is_alpha"                     : "doit contenir uniquement des lettres",
        "is_alpha_num_dash"            : "doit contenir uniquement des lettres, des chiffres et des tirets",
        "is_alpha_num"                 : "doit contenir uniquement des lettres et des chiffres",
        "is_array"                     : "doit être une tableau",
        "is_before"                    : "doit être avant le " + value1,
        "before_or_equal"              : "doit être le " + value1 + " ou avant",
        "between"                      : "doit être entre " + value1 + " et " + value2,
        "between_kilobytes"            : "doit être entre " + value1 + " kb et " + value2 + " kb",
        "between_characters"           : "doit être entre " + value1 + " caractères et " + value2 + " caractères",
        "between_items"                : "doit être entre " + value1 + " éléments et " + value2 + " éléments",
        "is_boolean"                   : "doit être un booléen",
        "is_confirmed"                 : "doit être confirmé",
        "is_valid_date"                : "doit être une date valide",
        "not_equals#:date"             : "doit être le " + value1,
        "invalid_date_format"          : "format de date invalide",
        "not_different"                : "doit être différent de " + value1,
        "must_be_digits"               : "doit être un nombre de longueur " + value1,
        "must_be_between_digits"       : "doit être un nombre entre " + value1 + " et " + value2,
        "invalid_image_dimensions"     : "dimension de l'image invalide",
        "duplicate_value"              : "ne doit pas contenir 2 fois la même valeur",
        "invalid_email_format"         : "format d'adresse courriel invalide",
        "not_exists"                   : "n'existe pas",
        "is_file"                      : "n'est pas un fichier",
        "must_have_value"              : "doit contenir une valeur",
        "greater_than"                 : "doit être plus grand que " + value1,
        "greater_than_kilobytes"       : "le fichier doit être plus gros que " + value1 + " kb",
        "greater_than_characters"      : "doit contenir plus que " + value1 + " caractères",
        "greater_than_items"           : "doit contenir plus de " + value1 + " éléments",
        "greater_than_equal"           : "doit être plus grand ou égale à " + value1,
        "greater_than_equal_kilobytes" : "le fichier doit être plus gros ou égale à " + value1 + " kb",
        "greater_than_equal_characters": "doit contenir plus ou exactement " + value1 + " caractères",
        "greater_than_equal_items"     : "doit contenir plus ou exactement " + value1 + " éléments",
        "is_image"                     : "doit être une image",
        "not_in_values"                : "n'est pas dans les valeurs proposées",
        "not_in_:other"                : "n'est pas dans " + value1,
        "is_int"                       : "doit être un chiffre",
        "is_IP_address"                : "doit être une adresse IP",
        "is_IPv4_address"              : "doit être une adresse IPv4",
        "is_IPv6_address"              : "doit être une adresse IPv6",
        "is_JSON_string"               : "doit être de format JSON",
        "less_than"                    : "doit être plus petit que " + value1,
        "less_than_kilobytes"          : "le fichier doit être plus petit que " + value1 + " kb",
        "less_than_characters"         : "doit contenir moins que " + value1 + " caractères",
        "less_than_items"              : "doit contenir moins de " + value1 + " éléments",
        "less_than_equal"              : "doit être plus petit ou égale à " + value1,
        "less_than_equal_kilobytes"    : "le fichier doit être plus petit ou égale à " + value1 + " kb",
        "less_than_equal_characters"   : "doit contenir moins ou exactement " + value1 + " caractères",
        "less_than_equal_items"        : "doit contenir moins ou exactement " + value1 + " éléments",
        "max"                          : "doit être au maximum " + value1,
        "max_kilobytes"                : "doit être au maximum " + value1 + " kb",
        "max_characters"               : "doit être au maximum " + value1 + " caractères",
        "max_items"                    : "doit être au maximum " + value1 + " éléments",
        "type_must_be"                 : "le fichier doit être de type :" + value1,
        "min"                          : "doit être au minimum " + value1,
        "min_kilobytes"                : "doit être au minimum " + value1 + " kb",
        "min_characters"               : "doit être au minimum " + value1 + " caractères",
        "min_items"                    : "doit être au minimum " + value1 + " éléments",
        "cannot_be_in_values"          : "ne peut être dans la liste",
        "invalid_format"               : "format invalide",
        "is_number"                    : "doit être un chiffre",
        "is_present"                   : "doit être présent",
        "is_required"                  : "est requis",
        "is_required_if"               : "est requis si le champ " + value1 + " équivaut à " + value2,
        "is_required_unless"           : "est requis uniquement si le champ " + value1 + " n'est pas vide",
        "required_with"                : "le champ est requis avec les champs " + value1,
        "required_with_all"            : "le champ est requis avec tout les champs " + value1,
        "required_without"             : "le champ est requis si les champs " + value1 + "ne sont pas présents",
        "required_without_all"         : "le champ est requis si tout les champs " + value1 + "ne sont pas présents",
        "is_same"                      : "doit être " + value1,
        "size"                         : "doit être égal à " + value1,
        "size_kilobytes"               : "doit être de " + value1 + " kb",
        "size_characters"              : "doit contenir " + value1 + " caractères",
        "size_items"                   : "doit contenir " + value1 + " éléments",
        "starts_with"                  : "doit commencer par " + value1,
        "is_string"                    : "doit être un text",
        "invalid_timezone"             : "invalide fuseau horaire",
        "is_used"                      : "est utilisé",
        "upload_failed"                : "téléchargement échoué",
        "is_url"                       : "doit être un URL",
        "is_UUID"                      : "doit être un UUID",
        "is_not_valid"                 : "n'est pas valide",
    };

    if (traduction[errorMessage]) {

        return traduction[errorMessage];
    }
    return errorMessage;

}

export default function translateErrorMessage(values) {

    if (Boolean(values)) {
        return values.map(error => {
            const errorsValues = error.split("#");
            return getErrorMessage(errorsValues[0], errorsValues[1], errorsValues[2]);
        });
    }
}