import React, {Component}    from "react";
import TextField             from "@material-ui/core/TextField";
import translateErrorMessage from "app/services/lang/translateErrorMessage";

class TextFieldComponent extends Component {

    render() {
        return <TextField
            {...this.props}
            className={`${this.props.margins} ${this.props.className}`}
            label={this.props.label}
            name={this.props.name}
            error={Boolean(this.props.errors)}
            helperText={translateErrorMessage(this.props.errors)}
            value={this.props.value}
            onChange={(event) => this.props.onChange(event)}
        />;
    }
}

TextFieldComponent.defaultProps = {margins: "mb-10 mt-10"};

export default (TextFieldComponent);