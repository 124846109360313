import React, {Component} from "react";
import {FontAwesomeIcon}  from "@fortawesome/react-fontawesome";
import GenericModelButton from "app/main/genericComponents/buttons/modelButtons/GenericModelButton";
import {faBarcodeAlt}     from "@fortawesome/pro-regular-svg-icons";
import CustomPopover      from "app/main/genericComponents/CustomPopover";
import {apiUrl}           from "app/services/axios/configApi";
import {Typography}       from "@material-ui/core";

class StockButton extends Component {

    constructor(props) {
        super(props);
        this.state = {anchor: {}}
    }

    renderSupplyPicture() {
        if(!Boolean(this.props.stock)){
            return <></>
        }

        return <div className={"text-center"}>
            {Boolean(this.props.stock.supply.pictures[0]) &&
                <img src={apiUrl + this.props.stock.supply.pictures[0].url}
                     alt={""}
                     style={{objectFit: "contain"}}
                     width={"200px"}
                     height={"200px"}
                />}
            <Typography className={"font-bold"}> {this.props.stock.supply.display_name} </Typography>
            {Boolean(this.props.stock.mac_address) && (
                <Typography> {"Mac : " + this.props.stock.mac_address} </Typography>
            )}
            {Boolean(this.props.stock.serial_no) && (
                <Typography> {"Numéro de série : " + this.props.stock.serial_no} </Typography>
            )}
        </div>
    }

    render() {
        return (
            <>
                <CustomPopover content={this.renderSupplyPicture()}>
                    <GenericModelButton
                        {...this.props}
                        url={"/equipements/" + this.props.stock.id}
                        buttontext={this.props.stock.ihr_code}
                        onMouseEnter={(event) => this.setState({anchor: event.currentTarget})}
                        onMouseLeave={() => this.setState({anchor: null})}
                        startIcon={this.props.withstarticon ?
                            <FontAwesomeIcon icon={faBarcodeAlt} className={"opacity-75 ml-4"}/> : ""}
                    />
                </CustomPopover>
            </>
        );
    }
}

StockButton.defaultProps = {withstarticon: 1};

export default (StockButton);
