import React      from "react";
import Grid       from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export function channel_in_conflict(errors) {
    return <div className={"max-w-sm"}>
        <Typography className={"text-lg"}>Le client possède déjà ces chaines</Typography>
        <Grid container>
            {errors.channels.slice(0, 10)
                   .map(channel => {
                       return <Grid item xs={3}>
                           {channel.name_fr}
                       </Grid>;
                   })}
        </Grid>
    </div>;
}
