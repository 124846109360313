import React      from "react";
import Typography from "@material-ui/core/Typography";

export function Channel_not_exist_in_area(errors) {
    return <div className={"max-w-sm"}>
        <Typography>La chaine {errors.channel} n'exist pas dans la zone {errors.service_area}</Typography>
        <Typography>Si celle-ci devrait l'être veuillez contacter votre superviseur.</Typography>
    </div>;
}

