import React        from "react";
import Typography   from "@material-ui/core/Typography";
import Grid         from "@material-ui/core/Grid";
import SupplyButton from "app/main/genericComponents/buttons/modelButtons/models/SupplyButton";

export function linked_to_supply(errors) {
    return <div className={"max-w-sm"}>
        <Typography>L'unité de mesure {errors.unit.name} est utilisé par des matériaux.</Typography>
        <Grid container>
            {errors.linked_supplies.slice(0, 10).map(supply => {
                return <Grid item xs={6} key={supply.id} className={"p-8 text-center flex"}>
                    <SupplyButton supply={supply}/>
                </Grid>;
            })}
        </Grid>
    </div>;
}

