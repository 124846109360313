import React                  from "react";
import { Icon, IconButton }   from "@material-ui/core";
import _                      from "@lodash";
import { bindActionCreators } from "redux";
import * as Actions           from "app/store/actions";
import { FontAwesomeIcon }    from "@fortawesome/react-fontawesome";
import { faChevronRight }     from "@fortawesome/pro-light-svg-icons/faChevronRight";
import { faChevronLeft }      from "@fortawesome/pro-light-svg-icons/faChevronLeft";
import { connect }            from "react-redux";

const NavbarFoldedToggleButton = ({settings, setDefaultSettings, children, className}) => {
    return (
        <IconButton
            className={className}
            onClick={() => {
                setDefaultSettings(_.set({}, "layout.config.navbar.folded", ! settings.layout.config.navbar.folded));
            }}
            color="inherit"
        >

            {settings.layout.config.navbar.folded && (<FontAwesomeIcon size="lg" icon={faChevronLeft}/>)}
            {! settings.layout.config.navbar.folded && (<FontAwesomeIcon size="lg" icon={faChevronRight}/>)}
        </IconButton>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setDefaultSettings: Actions.setDefaultSettings,
    }, dispatch);
}

function mapStateToProps({fuse}) {
    return {
        settings: fuse.settings.current,
    };
}

NavbarFoldedToggleButton.defaultProps = {
    children: <Icon>menu</Icon>,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarFoldedToggleButton);
