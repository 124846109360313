import React, {Component}                    from "react";
import {connect}                             from "react-redux";
import {Typography}                          from "@material-ui/core";
import PrimaryButton                         from "app/main/genericComponents/buttons/rectangleButtons/PrimaryButton";
import {getNotifications, readNotifications} from "app/services/axios/Common/notification";
import * as Actions                          from "app/main/store/common/actions";
import {bindActionCreators} from "redux";
import FabButton            from "app/main/genericComponents/buttons/IconButtons/FabButton";
import {FontAwesomeIcon}    from "@fortawesome/react-fontawesome";
import {faHistory}                           from "@fortawesome/pro-solid-svg-icons/faHistory";
import Hidden                                from "@material-ui/core/Hidden";
import * as notifications                    from "app/main/genericComponents/notification/notificationType/index";
import {InvalidNotification}                 from "app/main/genericComponents/notification/notificationType/invalidNotification";

class ShowNotifications extends Component {

    render() {
        return <div className={"mt-16"}>
            <div className={"flex justify-between"}>
                <Typography variant={"h4"}>Notifications</Typography>
                <div>

                    <Hidden xsDown>
                        <PrimaryButton
                            className={"mr-8"}
                            buttontext={"Marquer tout comme lu"}
                            onClick={() => readNotifications(
                                this.props.notifications
                                    .filter((notification) => !Boolean(notification.read_at))
                                    .map(notification => notification.id),
                            )
                                .then(() => {
                                    getNotifications().then((response) => {
                                        this.props.updateNotification(response.data.data);
                                    });
                                })}
                        />
                    </Hidden>

                    <FabButton
                        buttontext={<FontAwesomeIcon icon={faHistory} size={"lg"}/>}
                        onClick={() => getNotifications()
                            .then((response) => {
                                this.props.updateNotification(response.data.data);
                            })}
                        tootlip="Rafraichir les notifications"
                    />
                </div>
            </div>

            {this.props.notifications
                .sort((notification1, notification2) => {
                    if (!Boolean(notification1.read_at) && Boolean(notification2.read_at)) {
                        return -1;
                    }
                    if (!Boolean(notification2.read_at) && Boolean(notification1.read_at)) {
                        return 1;
                    }
                    return new Date(notification1.created_at) - new Date(notification1.created_at);
                })
                .map((notification, index) => {
                    if (Boolean(notifications[notification.type])) {
                        return notifications[notification.type](notification);
                    } else {
                        return InvalidNotification(notification);
                    }
                })
            }
        </div>;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({updateNotification: Actions.updateNotification,}, dispatch);
}

function mapStateToProps({CommonCombineReducer}) {
    return {notifications: CommonCombineReducer.ReducersNotification.notifications,};
}

export default (connect(mapStateToProps, mapDispatchToProps)(ShowNotifications));
