import { Component } from "react";
import { connect }   from "react-redux";

class IfPanel extends Component {
    render() {
        return (this.props[this.props.currentPanel]) ? this.props[this.props.currentPanel] : null;
    }
}

function mapStateToProps({fuse}) {
    return {
        currentPanel: fuse.navigation.currentPanel,
    };
}

export default (connect(mapStateToProps)(IfPanel));