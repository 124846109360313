import { AT_COMMENT } from "app/main/store/common/actions/actions-types";

const initialState = {
    comments       : [],
};

export default function(state = initialState, action) {

    switch (action.type) {

        case AT_COMMENT.SET_COMMENTS: {
            let newState = {
                ...state,
                comments: action.payload,
            };
            return newState;
        }

        default : {
            return state;
        }
    }
}