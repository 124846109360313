import React, { Component } from "react";
import PrimaryButton        from "app/main/genericComponents/buttons/rectangleButtons/PrimaryButton";
import { NavLink }          from "react-router-dom";

class GenericModelButton extends Component {

    render() {
        return (<PrimaryButton
                {...this.props}
                className={(this.props.capitalized) ? "px-8 " + this.props.className : "px-8 normal-case " + this.props.className}
                component={NavLink} to={this.props.url}
                buttontext={this.props.buttontext}
            />
        );
    }
}

export default (GenericModelButton);
