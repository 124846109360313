import React, {Component}  from "react";
import {readAllWarehouse}  from "app/services/axios/Inventory/warehouse";
import {readAllTrucks}     from "app/services/axios/Inventory/truck";
import {readAllDeliveries} from "app/services/axios/Inventory/delivery";
import {readAllNodes}      from "app/services/axios/Network/node";
import {readAllCells}      from "app/services/axios/Network/cell";
import {connect}           from "react-redux";
import ApiCallSelectField  from "app/main/genericComponents/formField/SelectFields/genericSelectField/ApiCallSelectField";

class StorageableSelectField extends Component {

    constructor(props) {
        super(props);
        this.state = {needUpdate: false};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.storageableType !== prevProps.storageableType) {
            this.setState({needUpdate: true});
        }
    }

    getStorageableItems() {
        switch (this.props.storageableType) {
            case"warehouse":
                return readAllWarehouse();
            case"truck":
                return readAllTrucks();
            case"delivery":
                return readAllDeliveries();
            case"node":
                return readAllNodes();
            case"cell":
                return readAllCells();
            default:
                return readAllWarehouse();
        }
    }

    mapItems(items) {
        if (this.props.storageableType === "delivery") {
            items = items.map(delivery => {
                return {value: delivery, label: delivery.delivery_no};
            })
        } else {
            items = items.map(storage => {
                return {label: storage.name, value: storage};
            })
        }

        if (this.props.currentPanel === "warehousePanel" && this.props.withDefaultValue && this.props.storageableType === "warehouse") {
            const warehouse = items.find(item => item.value.id === this.props.currentWarehouse.id);
            this.props.onChange({value: warehouse.value});
        }
        if (this.props.currentPanel === "truckPanel" && this.props.withDefaultValue && this.props.storageableType === "truck") {
            const truck = items.find(item => item.value.id === this.props.currentTruck.id);
            this.props.onChange({value: truck.value});
        }

        return items;
    }

    render() {
        return <ApiCallSelectField
            {...this.props}
            needUpdate={this.state.needUpdate}
            afterUpdate={() => this.setState({needUpdate: false})}
            getItems={() => this.getStorageableItems()}
            mapItems={items => this.mapItems(items)}
        />;
    }
}

StorageableSelectField.defaultProps = {
    withDefaultValue: true,
    label           : "Entrepôt"
};

function mapStateToProps({fuse}) {
    return {
        currentWarehouse: fuse.navigation.currentWarehouse,
        currentTruck    : fuse.navigation.currentTruck,
        currentPanel    : fuse.navigation.currentPanel,
    };
}

export default connect(mapStateToProps)(StorageableSelectField);