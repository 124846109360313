import React          from "react";
import Grid           from "@material-ui/core/Grid";
import Typography     from "@material-ui/core/Typography";
import DeliveryButton from "app/main/genericComponents/buttons/modelButtons/models/DeliveryButton";

export function delivery_destination(errors) {
    return <div className={"max-w-sm"}>
        <Typography className={"text-lg"}>Cet entrepôt est la destination des livraisons suivantes :</Typography>
        <Grid container>
            {errors.deliveries.slice(0, 10)
                   .map(delivery => {
                       return <Grid item xs={3}>
                           <DeliveryButton className={"m-8"} delivery={delivery}/>
                       </Grid>;
                   })}
        </Grid>
    </div>;
}
