import React, { Component }    from "react";
import { FontAwesomeIcon }     from "@fortawesome/react-fontawesome";
import { faTruckLoading } from "@fortawesome/pro-solid-svg-icons/faTruckLoading";
import GenericModelButton from "app/main/genericComponents/buttons/modelButtons/GenericModelButton";

class DeliveryButton extends Component {

    render() {
        return (<GenericModelButton
                {...this.props}
                capitalized={1}
                url={"/livraisons/" + this.props.delivery.id}
                buttontext={this.props.delivery.delivery_no}
                startIcon={<FontAwesomeIcon icon={faTruckLoading} className={"opacity-75 ml-4"}/>}
            />
        );
    }
}

export default (DeliveryButton);
