import React, {Component} from "react";
import {Fab}              from "@material-ui/core";
import {withStyles}       from "@material-ui/styles";
import Tooltip            from "@material-ui/core/Tooltip";
import HasPermissions     from "app/main/genericComponents/HasPermissions";

const styles = ({
    root: {
        minHeight: "40px",
        minWidth : "40px",
    },
});


class FabButton extends Component {

    getToolTip(props) {

        const { tooltip, ...rest } = props
        if (Boolean(tooltip)) {
            return <Tooltip title={tooltip}>
                <span>
                    {this.getButton(rest)}
                </span>
            </Tooltip>
        } else {
            return this.getButton(rest);
        }
    }

    getButton(props) {
        return <Fab
            {...props}
            color={props.color}
            size={props.size}
            className={props.className + " " + props.classes.root}
            onClick={(event) => props.onClick(event)}>
            {props.buttontext}
        </Fab>
    }

    render() {
        const { requiredPermissions,requirementType, ...rest } = this.props
        return <HasPermissions requiredPermissions={requiredPermissions} requirementType={requirementType}>
            {this.getToolTip(rest)}
        </HasPermissions>
    }
}

FabButton.defaultProps = {
    onClick        : () => {
    },
    color          : "secondary",
    size           : "small",
    tooltip        : "",
    requirementType: "all"
};

export default withStyles(styles)(FabButton);