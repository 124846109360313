import React                              from "react";
import {FontAwesomeIcon}                                         from "@fortawesome/react-fontawesome";
import {faAtom, faPeopleGroup, faWarehouseAlt, faWrench} from "@fortawesome/pro-solid-svg-icons";
import {
    faGift,
    faCoffinCross,
    faJackOLantern,
    faSleigh,
    faTreeChristmas,
    faSpiderBlackWidow,
    faGlassCheers,
    faCalendarAlt,
    faBirthdayCake,
    faRabbit,
    faEgg,
    faShoppingBasket,
    faHeart,
    faFlowerDaffodil,
    faKissWinkHeart
}                                                                from "@fortawesome/pro-duotone-svg-icons";

const AdminPanel = {
    name                  : "adminPanel",
    traduction            : "AtomBo",
    logo                  : <FontAwesomeIcon icon={faAtom} style={{fontSize: 40}}/>,
    permission            : "administrator_panel",
    logoChristmas         : <FontAwesomeIcon icon={faTreeChristmas} style={{
        "--fa-primary-color"    : "#00873E",
        "--fa-secondary-color"  : "#FDBA29",
        fontSize                : 40,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorChristmas: "#C30F16",

    logoHalloween         : <FontAwesomeIcon icon={faJackOLantern} style={{
        "--fa-primary-color"    : "black",
        "--fa-secondary-color"  : "#EB6123",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorHalloween: "#EB6123",

    logoNewYear         : <FontAwesomeIcon icon={faGlassCheers} style={{
        "--fa-primary-color"    : "#D9E8FF",
        "--fa-secondary-color"  : "#FEB444",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorNewYear: "#BABABA",

    logoEaster         : <FontAwesomeIcon icon={faRabbit} style={{
        "--fa-primary-color"    : "pink",
        "--fa-secondary-color"  : "white",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorEaster: "#A2C1E0",

    logoValentine         : <FontAwesomeIcon icon={faHeart} style={{
        "--fa-primary-color"    : "white",
        "--fa-secondary-color"  : "pink",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorValentine: "red",
};

const WarehousePanel = {
    name      : "warehousePanel",
    traduction: "Entrepôt",
    permission: "warehouse_panel",
    logo      : <FontAwesomeIcon icon={faWarehouseAlt} style={{fontSize: 30}}/>,

    logoChristmas         : <FontAwesomeIcon icon={faGift} style={{
        "--fa-primary-color"    : "#FDBA29",
        "--fa-secondary-color"  : "#00873E",
        fontSize                : 30,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorChristmas: "#C30F16",

    logoHalloween         : <FontAwesomeIcon icon={faCoffinCross} style={{
        "--fa-primary-color"    : "#9F6333",
        "--fa-secondary-color"  : "black",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorHalloween: "#EB6123",

    logoNewYear         : <FontAwesomeIcon icon={faCalendarAlt} style={{
        "--fa-primary-color"    : "#FEB444",
        "--fa-secondary-color"  : "white",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorNewYear: "#BABABA",

    logoEaster         : <FontAwesomeIcon icon={faEgg} style={{
        "--fa-primary-color"    : "#A2C03B",
        "--fa-secondary-color"  : "#0CDBF0",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorEaster: "#A2C1E0",

    logoValentine         : <FontAwesomeIcon icon={faFlowerDaffodil} style={{
        "--fa-primary-color"    : "pink",
        "--fa-secondary-color"  : "green",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorValentine: "red",
};

const TruckPanel = {
    name      : "truckPanel",
    traduction: "Technicien",
    permission: "truck_panel",
    logo      : <FontAwesomeIcon icon={faWrench} style={{fontSize: 40}}/>,

    logoChristmas         : <FontAwesomeIcon icon={faSleigh} style={{
        "--fa-primary-color"    : "#00873E",
        "--fa-secondary-color"  : "#FDBA29",
        fontSize                : 30,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorChristmas: "#C30F16",

    logoHalloween         : <FontAwesomeIcon icon={faSpiderBlackWidow} style={{
        "--fa-primary-color"    : "black",
        "--fa-secondary-color"  : "red",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorHalloween: "#EB6123",

    logoNewYear         : <FontAwesomeIcon icon={faBirthdayCake} style={{
        "--fa-primary-color"    : "white",
        "--fa-secondary-color"  : "#FEB444",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorNewYear: "#BABABA",

    logoEaster         : <FontAwesomeIcon icon={faShoppingBasket} style={{
        "--fa-primary-color"    : "#E3BB83",
        "--fa-secondary-color"  : "#7D5134",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorEaster: "#A2C1E0",

    logoValentine         : <FontAwesomeIcon icon={faKissWinkHeart} style={{
        "--fa-primary-color"    : "pink",
        "--fa-secondary-color"  : "yellow",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorValentine: "red",
};

const ContractorPanel = {
    name      : "contractorPanel",
    traduction: "Sous-traitant",
    permission: "contractor_panel",
    logo      : <FontAwesomeIcon icon={faPeopleGroup} style={{fontSize: 30}} color={"#fdba29"}/>,

    logoChristmas         : <FontAwesomeIcon icon={faSleigh} style={{
        "--fa-primary-color"    : "#00873E",
        "--fa-secondary-color"  : "#FDBA29",
        fontSize                : 30,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorChristmas: "#C30F16",

    logoHalloween         : <FontAwesomeIcon icon={faSpiderBlackWidow} style={{
        "--fa-primary-color"    : "black",
        "--fa-secondary-color"  : "red",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorHalloween: "#EB6123",

    logoNewYear         : <FontAwesomeIcon icon={faBirthdayCake} style={{
        "--fa-primary-color"    : "white",
        "--fa-secondary-color"  : "#FEB444",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorNewYear: "#BABABA",

    logoEaster         : <FontAwesomeIcon icon={faShoppingBasket} style={{
        "--fa-primary-color"    : "#E3BB83",
        "--fa-secondary-color"  : "#7D5134",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorEaster: "#A2C1E0",

    logoValentine         : <FontAwesomeIcon icon={faKissWinkHeart} style={{
        "--fa-primary-color"    : "pink",
        "--fa-secondary-color"  : "yellow",
        fontSize                : 35,
        "--fa-secondary-opacity": 1,
    }}/>,
    octogoneColorValentine: "red",
};

const panelConfig = {
    adminPanel     : AdminPanel,
    warehousePanel : WarehousePanel,
    truckPanel     : TruckPanel,
    contractorPanel: ContractorPanel,
};

export default panelConfig;