import React, { Component }   from "react";
import { IconButton }         from "@material-ui/core";
import { FontAwesomeIcon }    from "@fortawesome/react-fontawesome";
import { faBell }             from "@fortawesome/pro-solid-svg-icons/faBell";
import * as Actions           from "app/main/store/common/actions";
import { connect }            from "react-redux";
import CommonCombineReducer   from "app/main/store/common/reducers";
import withReducer            from "app/store/withReducer";
import { bindActionCreators } from "redux";
import { getNotifications }   from "app/services/axios/Common/notification";

class QuickPanelNotificationToggleButton extends Component {

    componentDidMount() {

        if (Notification.permission !== "granted") {
            Notification.requestPermission();
        }

        getNotifications().then((response) => {
            this.props.updateNotification(response.data.data);
        });

        setInterval(() => {
            getNotifications().then((response) => {
                if (this.props.notifications.length < response.data.data.length) {
                    this.ShowBrowserNotification(response.data.data[response.data.data.length - 1]);
                }
                this.props.updateNotification(response.data.data);

            });
        }, 300000);
    }

    ShowBrowserNotification(notification) {
        const text = this.getTextForBrowserNotification(notification);
        if (Notification.permission !== "granted") {
            Notification.requestPermission();
        } else {
            new Notification(text);

        }
    }

    getTextForBrowserNotification(notification) {
        switch (notification.type) {

            case "DeliveryReady":
                return " La livraison " + notification.infos.delivery_no + " est prête à être réceptionnée. ";

            case "NewResponsibleForDelivery":
                return " Vous êtes le nouveau responsable de la livraison " + notification.infos.delivery_no + ".";

            case "DeliveryCommented":
                return " La livraison " + notification.infos.delivery_no + " est a été commentée. ";

            default :
                return "";
        }
    }

    render() {
        return (
            <IconButton className="w-64 h-64" onClick={() => this.props.toggleNotificationPanel()}>
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faBell} size={"lg"}/>
                    {this.props.notifications.filter((notification) => ! Boolean(notification.read_at)).length !== 0 && (
                        <span className="fa-layers-counter text-3xl" style={{background: "Tomato"}}>
                            {this.props.notifications.filter((notification) => ! Boolean(notification.read_at)).length}
                        </span>
                    )}
                </span>
            </IconButton>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleNotificationPanel: Actions.toggleNotificationPanel,
        updateNotification     : Actions.updateNotification,
    }, dispatch);
}

function mapStateToProps({CommonCombineReducer}) {
    return {
        notifications: CommonCombineReducer.ReducersNotification.notifications,
    };
}

export default withReducer("CommonCombineReducer", CommonCombineReducer)(connect(mapStateToProps, mapDispatchToProps)(QuickPanelNotificationToggleButton));
