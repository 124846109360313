import React      from "react";
import Typography from "@material-ui/core/Typography";

export function customer_stb_limit_reached(errors) {
    return <>
        <Typography>La limite de décodeurs du client est atteinte.</Typography>
        <Typography>Veuillez en retirer un si nécessaire.</Typography>
    </>;
}

