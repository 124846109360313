import { AT_COMMENT } from "app/main/store/common/actions/actions-types";

export function setComments(comments) {
    return function(dispatch) {
        dispatch({
            type   : AT_COMMENT.SET_COMMENTS,
            payload: comments,
        });
    };
}
