const settingsConfig = {
    layout          : {
        style: 'layout1', // layout-1 layout-2 layout-3
        config: {} // checkout layout configs at app/Atombo-configs/layout-1/Layout1Config.js
    },
    customScrollbars: true,
    theme           : {
        main   : 'fuse',
        navbar : 'mainThemeLight',
        toolbar: 'mainThemeLight',
        footer : 'mainThemeDark'
    }
};

export default settingsConfig;
