import React      from "react";
import Typography from "@material-ui/core/Typography";
import Grid       from "@material-ui/core/Grid";

export function invalid_channel_selection(errors) {

    return <div className={"max-w-sm"}>
        <Typography className={"text-lg font-semibold"}>Les chaines suivantes sont invalides</Typography>
        <br/>

        <Typography className={"font-semibold mb-4"}>Les chaines suivantes sont indisponible</Typography>
        <Grid container className={"mb-8"}>
            {errors.unavailable_channels.slice(0, 10)
                   .map(channel => {
                       return <Grid item xs={6}>
                           {channel.name_fr}
                       </Grid>;
                   })}
        </Grid>

        <Typography className={"font-semibold mb-4"}>Les chaines suivantes ne sont pas disponibles à la carte</Typography>
        <Grid container>
            {errors.not_a_la_carte_channels.slice(0, 10)
                   .map(channel => {
                       return <Grid item xs={6}>
                           {channel.name_fr}
                       </Grid>;
                   })}
        </Grid>
    </div>;
}

