import React      from "react";
import Typography from "@material-ui/core/Typography";
import translate  from "app/services/lang/translate";
import Grid       from "@material-ui/core/Grid";

export function invalid_storage_type(errors) {

    return <div className={"max-w-sm"}>
        <Typography className={"text-lg font-semibold"}>
            Impossible de faire le changement à cause du type de l'entrepôt.
        </Typography>

        <br/>

        <Typography className={"mb-4"}>Le type de l'entrepôt courant est
            : {translate(errors.current_storage_type)}</Typography>
        <Grid container className={"mb-8"}>
            <Grid item xs={6}>
                <Typography>Les types d'entrpôts valides sont :</Typography>
            </Grid>
            <Grid item container xs={6}>
                {errors.valid_storageable_types.map(storage_type => {
                    return <Grid item container xs={4}>
                        <Typography>{translate(storage_type)}</Typography>
                    </Grid>;
                })}
            </Grid>
        </Grid>
    </div>;
}

