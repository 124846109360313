import {AT_USER} from '../actions/actions-types'

function loadState() {
    return {
        data: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
    }
}

const user = function (state = loadState(), action) {
    let newState;
    switch (action.type) {

        case AT_USER.USER_UPDATE_CURRENT: {
            newState = {
                ...state,
                data: action.payload,
            };
            localStorage.setItem('userInfo', JSON.stringify(action.payload));
            break;
        }
        default: {
            newState ={
                ...state
            };
            break;
        }
    }
    return newState;
};

export default user;
