import React               from "react";
import { Typography }      from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckLoading }  from "@fortawesome/pro-solid-svg-icons/faTruckLoading";
import history             from "@history";
import NotificationBase    from "app/main/genericComponents/notification/NotificationBase";

export function DeliveryCommented(props) {

    return (
        <NotificationBase
            content={<Typography className={"align-middle"}>
                <FontAwesomeIcon icon={faTruckLoading}/>
                {" La livraison " + props.notification.infos.delivery_no + " est a été commentée. "}
            </Typography>}
            onRedirect={() => history.push("/livraisons/" + props.notification.infos.delivery_id)}
            notification={props.notification}
        />
    );
}


