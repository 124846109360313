import React, {Component} from "react";
import {Button}           from "@material-ui/core";
import Tooltip            from "@material-ui/core/Tooltip";
import HasPermissions     from "app/main/genericComponents/HasPermissions";

export default class PrimaryButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            timeout: "",
        };
    }

    onClick(event) {
        this.setState({disabled: true});
        this.props.onClick(event);
        let timeoutId = setTimeout(() => {
            this.setState({disabled: false});
        }, 1000);
        this.setState({timeout : timeoutId});
    }



    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }

    getToolTip(props) {

        const { tooltip, ...rest } = props
        if (Boolean(tooltip)) {
            return <Tooltip title={tooltip}>
                <span>
                    {this.getButton(rest)}
                </span>
            </Tooltip>
        } else {
            return this.getButton(rest);
        }
    }

    getButton(props) {
        return <Button
            {...props}
            size={props.size}
            onClick={(event) => this.onClick(event)}
            disabled={props.disabled || this.state.disabled}
            variant={props.variant}
            disableElevation={props.disableElevation}
            color={props.color}
            className={"font-semibold " + props.className}
        >
            {props.buttontext}
        </Button>
    }

    render() {
        const { requiredPermissions,requirementType, ...rest } = this.props

        return <HasPermissions requiredPermissions={requiredPermissions} requirementType={requirementType}>
            {this.getToolTip(rest)}
        </HasPermissions>
    }
}

PrimaryButton.defaultProps = {
    onClick         : () => {
    },
    buttontext      : "",
    tooltip         : "",
    size            : "medium",
    variant         : "contained",
    color           : "secondary",
    disableElevation: false,
    requirementType: "all",
};
